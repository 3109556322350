import React, { useState, useContext } from 'react';
import './Newvideo.css';
import Navbar from '../../User/Navbar/Navbar';
import Footer from '../../User/Footer/Footer';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../../../context/UserContext';
import Loading from '../../User/Loading/Loading';
import { useSessionErrorHandler } from '../../User/Managesession';

function Newvideo() {

  const handleSessionError = useSessionErrorHandler();

  const { setSuccess,token, setError,setLoading,loading, setSuccessAction, user } = useContext(UserContext);



  const navigate = useNavigate();
  const {subSection,section} = useParams()
  const [showPopup, setShowPopup] = useState(false);
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [formData, setFormData] = useState([]);
  const [activeButton, setActiveButton] = useState('device');
  const [videoFile, setVideoFile] = useState(null);
  const [videoPreviewUrl, setVideoPreviewUrl] = useState('');
  const [title, setTitle] = useState('');
  const [youtubeurl, setYoutubeurl] = useState('');
  const [description, setDescription] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
// console.log(subSection,section)
  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setVideoFile(file);
      setVideoPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    if(e.target.value.length>999){
      alert('لقد تجاوزت الحد الأقصى للوصف وهو 1000 حرف')
      return
    }
    setDescription(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsFormSubmitted(true);

    if (title === '' || description === '') {
      return;
    }
    setShowPopup(false);
  };

  const addQuestionPopup = () => {
    setShowPopup(!showPopup);
  };

  const handleToggleContent = () => {
    setIsContentVisible(!isContentVisible);
  };

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
    setIsContentVisible(true);
  };

  const submitData = async () => {
    setIsFormSubmitted(true);
    console.log("title",title)
    if (title === '' || description === '') {
      alert('يرجى ملء جميع الحقول')
      return;
    }







      // Check if there are any errors
      if ( description && title ) {
        const formData = new FormData();
         formData.append('title', title);
        formData.append('description', description);
        formData.append('category', section);
        formData.append('subCategory', subSection);
        if(videoFile){
        formData.append('file', videoFile);
        }
        if(youtubeurl){
          console.log("inyoutube url",youtubeurl)
          formData.append("youtubeVideoUrl",youtubeurl)
        }



        try {
          setLoading(true)
          const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/video/upload?adminId=${user._id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'authorization':`Bearer ${token}`
            },
          });

          if (response.status === 200) {
            setLoading(false)
            navigate('/success'); // Navigate to success page
            setSuccessAction(`/videos/${response.data.response._id}`);
            setSuccess("video uploaded");
          }
        } catch (error) {
          setLoading(false)
          handleSessionError(error)
          console.error('Error uploading video:', error);
        }
        setLoading(false)
      }
    };


  const goBack = () => {
    navigate(-1);
  };


  const getEmbedUrl = (url) => {
    // Extract the video ID with a regular expression that handles various YouTube URL formats
    const videoIdMatch = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/|shorts\/|.*[?&]v=)|youtu\.be\/)([^#&?]*)/);

    if (videoIdMatch && videoIdMatch[1].length === 11) { // Check if the video ID is valid (11 characters)
      return `https://www.youtube.com/embed/${videoIdMatch[1]}`;
    } else {
      return ''; // Or display a user-friendly message for invalid URLs
    }

  };



  return (
    loading ?<Loading/>:
    <>
      <div className="addanewvideomain">
        <div className="newvideonav">
          <Navbar />
        </div>
        <div className="newvideocontainer">
          <div className="topupernavbar">
            <div><Icon onClick={goBack} icon="ep:arrow-left-bold" width="1.2em" height="1.2em" /></div>
            <div>اضافة فيديو جديد</div>
          </div>
          <div className="videodivnewvideo">
            { activeButton ==="youtube" && <>
{ youtubeurl ?
<>

<iframe
          width="100%"
          style={{borderRadius:"12px"}}
          height="100%"
          src={getEmbedUrl(youtubeurl)}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"  

          referrerPolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>


</>
  :
 <>
  <label htmlFor="videoUpload" className="custom-file-upload">
                  <Icon icon="ph:play-fill" width="1.2em" height="1.2em" />
                </label>
 </>
}</>}

{
activeButton === "device" && <>
            { videoPreviewUrl ? (
              <video controls>
                <source src={videoPreviewUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <>
                <label htmlFor="videoUpload" className="custom-file-upload">
                  <Icon icon="ph:play-fill" width="1.2em" height="1.2em" />
                </label>
                <input
                  type="file"
                  id="videoUpload"
                  accept="video/*"
                  onChange={handleVideoChange}
                  style={{ display: 'none' }}
                />
              </>
            )}
            </>
          }
          </div>
          <div className="videosbuttons">
            <button
              onClick={() => handleButtonClick('youtube')}
              style={{ backgroundColor: activeButton === 'youtube' ? '#B571AC' : '', color: activeButton === 'youtube' ? 'white' : '', border: '1px solid #B571AC' }}
            >
              فيديو من اليوتيوب
            </button>
            <button
              onClick={() => handleButtonClick('device')}
              style={{ backgroundColor: activeButton === 'device' ? '#B571AC' : '', color: activeButton === 'device' ? 'white' : '', border: '1px solid #B571AC' }}
            >
              ارفع فيديو
            </button>
          </div>

          {activeButton === "youtube" && (
            <div className="titleofvideo">
             <label>رابط الفيديو من اليوتيوب</label>
              <input
                type="text"
                placeholder='عنوان الفيديو'
                value={youtubeurl}
                onChange={(e)=>setYoutubeurl(e.target.value)}
                className={isFormSubmitted && youtubeurl === '' ? 'input-error' : ''}
              />
            </div>
          )}

          <div className="titleofvideo">
              <label>عنوان الفيديو</label>
            <input
              type="text"
              placeholder='عنوان الفيديو'
              value={title}
              onChange={handleTitleChange}
              className={isFormSubmitted && title === '' ? 'input-error' : ''}
            />
          </div>

          <div className="descraptionofvideo">
            <div className="descrationvideoof">الوصف</div>
            <div className="belowdivwithborder">
              <textarea
                placeholder='الحد 1000 حرف'
                rows={10}
                cols={10}
                value={description}
                onChange={handleDescriptionChange}
                className={isFormSubmitted && description === '' ? 'input-error' : ''}
              />
            </div>
          </div>

          {uploadProgress > 0 && uploadProgress < 101 ? (
            <div className="upload-progress" style={{ border: uploadProgress === 100 && "2px solid #5D5D5D" }}>
              <div className="progress-bar" style={{ width: `${uploadProgress}%` }}></div>
            </div>
          ) : (
            <div className="addvideobuttonnew" onClick={submitData}>
              إضافة الفيديو
            </div>
          )}
        </div>
        <div className="newvideofooter">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Newvideo;
