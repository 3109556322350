import React, { useContext, useEffect, useState } from "react";
import "./Subcatageries.css";
import Navbar from "../../User/Navbar/Navbar";
import Footer from "../../User/Footer/Footer";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../../../context/UserContext";
import Loading from "../../User/Loading/Loading";
import { useSessionErrorHandler } from "../../User/Managesession";

export default function Adminsubcategory() {

  const handleSessionError = useSessionErrorHandler();

  const [popup, setPopup] = useState(false);
  const [newSubSectionName, setNewSubSectionName] = useState('');
  const { loading, setLoading, token } = useContext(UserContext);
  const [popup2, setPopup2] = useState(false);
  const [activeDepartmentIndex, setActiveDepartmentIndex] = useState(null);
  const [delsection, setDelsection] = useState(false);
  const [openmodel, setopenmodel] = useState(false);
  const { sectionId } = useParams();
  const [title, setTitle] = useState("");
  const [departments, setDepartments] = useState([]);
  const [newsectionName,setNewSectionName] = useState("")
  const [oldsectionName,setOldSectionName] = useState("")
  // const [sectionName,setSectionName] = useState([])
  const getsubsections = async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/section/getsubsections/${sectionId}`,{headers : {authorization : `Bearer ${token}`}}
      )
      .then((response) => {
        setDepartments(response.data.data.subSections);
        setTitle(response.data.data.title);
        setLoading(false);
        return;
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        return;
      });
  };
  const handleChange = (e) => {
    console.log(e.target.value)
    setNewSubSectionName(e.target.value);
  };

  useEffect(() => {
    getsubsections();
  }, []);

  const popupshow = (index,e) => {
    setPopup(!popup);
    e.stopPropagation();
    setActiveDepartmentIndex(activeDepartmentIndex !== index ? index : null);
  };

  const navigate = useNavigate();

  const admin_Addasectionnavigate = () => {
    navigate("/addsubcatageries");
  };

  const deletepopup = (e,oldname) => {
    setPopup2(false)
    setPopup(false)
    e.stopPropagation();
    console.log(oldname)
    setOldSectionName(oldname)
    setDelsection(!delsection);
  };

  const addsomething = (e,oldname) => {
    setPopup(false)
    setPopup2(false)
    e.stopPropagation();
    setOldSectionName(oldname)
    setopenmodel(!openmodel);
  };

  const goback = () => {
    navigate(-1);
  };

  const addeletefunction = async() => {

    console.log(oldsectionName)
   await axios.delete(`${process.env.REACT_APP_SERVER_URL}/section/deletesubsection/${title}?title=${oldsectionName}`,{headers : {authorization : `Bearer ${token}`}}).then((response)=>{
    console.log(response)
    setDepartments(response.data.response.subSections)
   }).catch((err)=>{
    console.log(err)
    handleSessionError(err);
    return
   })
   setDelsection(false)
   setOldSectionName('')
   setActiveDepartmentIndex(null)
  };

  const addfunctionclick = async () => {
    setopenmodel(false);
    setDelsection(false)
    setOldSectionName('')
    setActiveDepartmentIndex(null)
    setLoading(true)
    await axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/section/updateSubSectionName/${title}`,
        {
          newSubSectionTitle: newSubSectionName,
          oldSubSectionTitle: oldsectionName,
        },{headers : {authorization : `Bearer ${token}`}}
      )
      .then((res) => {
        console.log(res)
        setDepartments(res.data.data.subSections)
        setLoading(false)
        return
      }).catch((err)=>{
        console.log(err)
        handleSessionError(err);
        return
      })
      return
  };


  const handleDepartmentClick = (titel) => {
    if (title === 'الكتب') {
      navigate('/uploadedbooks', { state: { departmentTitle: titel } });
    } else if (title === 'الفيديوهات') {
      navigate('/uploadedvideos', { state: { departmentTitle: titel } });
    } else {
      console.log('Unknown title:', title);
    }
  };

  const removeback = ()=>{
    setopenmodel(false);
    setDelsection(false);
   setOldSectionName('')
   setActiveDepartmentIndex(null)
  }

  function falsePopup () {
    setDelsection(false)
   setOldSectionName('')
   setActiveDepartmentIndex(null)
  }

  return loading ? (
    <Loading />
  ) : (
    <div>
      <Navbar />

      <div className="arrowmaindiv">
        <div className="leftarrow">
          <Icon
            onClick={goback}
            className="leftarrowicon"
            icon="mdi:keyboard-arrow-left"
          />
        </div>
        <div>
          <h1>{title}</h1>
        </div>

        {/* <div className="threedotsdiv">
          <Icon
            onClick={(e) => popupshow2(e)}
            icon="bi:three-dots-vertical"
            width="2em"
            height="2em"
          />
          {popup2 && (
            <div className="smallpopupdiv">
              <div onClick={(e)=>addsomething(e)}>تعديل</div>
              <div onClick={(e)=>deletepopup(e,"")}>حذف</div>
            </div>
          )}
        </div> */}
      </div>

      {/* {(delsection || openmodel) && <div className="overlay" onClick={removeback}></div>} */}

      {delsection && (
        <div>
          <div className="mainparentdiv">
            <div className="popup-false" onClick={falsePopup}></div>
            <div className="deletedmaindiv">
              <h1>سبب الحذف </h1>
              <div className="insidethemaindiv">
                <textarea name="" id=""></textarea>
              </div>
              <div className="deletediconandtextdiv" onClick={addeletefunction}>
                <Icon icon="ic:baseline-delete" width="1.2em" height="1.2em" />
                <h3>حذف</h3>
              </div>
            </div>
          </div>
        </div>
      )}

      {openmodel && (
        <div>
          <div className="mainparentdiv" onClick={removeback} >
            <div
              className="addmodelmaindiv"
              onClick={(e) => {
                e.stopPropagation(e);
              }}
            >
              <h1>اسم القسم </h1>
              <input type="text" onChange={handleChange} />
              <div
                className="addmodalbtndiv"
                onClick={() => addfunctionclick(newSubSectionName)}
              >
                <h3>حفظ التعديل</h3>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="maindivofadmin_department">
        <h1>الأقسام</h1>
        <div className="mainflexdivofadmin">
          {departments && departments.length <= 0
            ?<> <div className="mainnocatageries">
              Click on + to add catageries
              </div> </>
            : departments.map((department, index) => (
                <div key={department.title} className="maindepartmentdiv"  onClick={() => handleDepartmentClick(department.title)}>
                  <Icon
                    onClick={(e) => popupshow(index,e )}
                    icon="bi:three-dots-vertical"
                    width="2em"
                    height="2em"
                  />
                  <h2>{department.title}</h2>
                  {popup && activeDepartmentIndex === index && (
                    <div className="smallpopupdiv">
                      <div onClick={(e)=>addsomething(e,department.title)}>تعديل</div>
                      <div onClick={(e)=>deletepopup(e,department.title)}>حذف</div>
                    </div>
                  )}
                </div>
              ))}
          <div
            className="maindepartmentdiv"
            onClick={admin_Addasectionnavigate}
          >
            <div className="plusicondivcontainer">
              <Icon icon="ic:round-plus" width="1.2em" height="1.2em" />
            </div>
            <h2> اضافة قسم فرعي جديد</h2>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
