import React, { useState, useRef, useContext } from "react";
import "./Readanalysis.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css"; // Import Calendar CSS
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import axios from "axios";
import Loading from "../Loading/Loading";
import { socket } from "../Bloodimageanalysis/globalsocket";
import heic2any from "heic2any"; // Import the HEIC conversion library
import { useSessionErrorHandler } from "../Managesession";
import { getCurrentDate, getCurrentTime, getCurrentTimeISO } from "../../../HelperFunctions";

export default function Readanalysis() {
  const handleSessionError = useSessionErrorHandler();
  const axiosInstance = axios.create({
    withCredentials: true,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  const {
    setSuccessAction,
    setSuccess,
    formData,
    setError,
    setPay,
    setPaytitle,
    paytitle,
    setFormData,
    user,
    pay,
    loading,
    setLoading,
    activeButtonmet,
    setActiveButtonmet,
    setPaymentSuccessAction,
    token,
  } = useContext(UserContext);

  const { first_name, last_name, email, phone } = user;
  const navigate = useNavigate();

  const [formsteps, setFormsteps] = useState("first");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [files, setFiles] = useState([]);
  const [submitAttempt, setSubmitAttempt] = useState(false);
  const [fadeOut, setFadeOut] = useState(false); // Add fadeOut state
  const [alert, setAlert] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = async (e) => {
    const selectedFiles = Array.from(e.target.files);
    console.log(selectedFiles);
    const convertedFiles = [];

    for (let file of selectedFiles) {
      if (file.type === "pdf" || file.name.toLowerCase().endsWith(".pdf")) {
        setAlert(true);
        setTimeout(() => {
          setAlert(false);
        }, 5000);
        return;
      }
      if (
        file.type === "image/heic" ||
        file.name.toLowerCase().endsWith(".heic")
      ) {
        try {
          const convertedBlob = await heic2any({
            blob: file,
            toType: "image/jpeg",
          });
          const convertedFile = new File(
            [convertedBlob],
            `${file.name.split(".")[0]}.jpg`,
            { type: "image/jpeg" }
          );
          convertedFiles.push(convertedFile);
        } catch (error) {
          console.error("Error converting HEIC to JPG:", error);
        }
      } else {
        convertedFiles.push(file);
      }
    }

    setFiles(convertedFiles);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "title") {
      setTitle(value);
    } else if (name === "description") {
      setDescription(value);
    } else if (name === "selectedOption") {
      console.log("value", value);
      setSelectedOption(value);
    }
  };

  const handleSubmit = async () => {
    let newPaytitle = "";
    setSubmitAttempt(true);
    if (selectedOption === "500.00") {
      setPay("500.00");
      newPaytitle = "قراءة تحليل (500 ريال)";
    } else if (selectedOption === "1000.00") {
      setPay("1000.00");
      newPaytitle = "قراءة تحليل +تصميم نظام غذائي(1000 ريال)";
    }
    console.log("services details", paytitle);
    if (title && description && selectedOption && files.length > 0) {
      const newFormData = new FormData();
      newFormData.append("title", title);
      newFormData.append("service_details", newPaytitle);
      newFormData.append("order_description", description);
      newFormData.append("order_amount",selectedOption);
      newFormData.append("payer_email", email);
      newFormData.append("payer_id", user._id);
      newFormData.append("payer_first_name", first_name);
      newFormData.append("payer_last_name", last_name);
      newFormData.append("payer_phone", user.phone_number);

      Array.from(files).forEach((file, index) => {
        newFormData.append(`files`, file);
      });

      setLoading(true);
      try {
        const response = await axiosInstance.post(
          `${process.env.REACT_APP_SERVER_URL}/edfapay/getpaymentdetails`,
          newFormData,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response);

        if (response.data.success) {
          const socketData = {
            Link: `/collobies/${response.data.requestID}`,
            Message: `طلب للخدمة ${user.first_name}`,
            Deliver_To: "admin",
            time: getCurrentTime(),
            date: getCurrentDate(),
            created_at:getCurrentTimeISO()
          };
          socket.emit("notification", socketData);
          setSuccess("address success");
          setFormData(null);
          setPaymentSuccessAction(`/`);
          setFadeOut(true);
          setTimeout(() => {
            setLoading(false);
            window.location.href = response.data.data;
          }, 500); // Adjust delay to match CSS transition
        }
      } catch (error) {
        handleSessionError(error);
        console.log(error);
        setFormData(null);
        setLoading(false);
        navigate("/error");
      }
    } else {
      setError("");
    }
  };

  const [value, setValue] = useState(new Date());
  const [showPopState, setShowPopState] = useState(false);
  const [activeItem, setActiveItem] = useState(""); // Track selected item (initially null)
  const [descraption, setDescraption] = useState("");

  const handleClick = (time) => {
    setActiveItem(time);
  };

  const scheduleData = [
    { time: "10:00" },
    { time: "9:00" },
    { time: "8:00" },
    { time: "13:00" },
    { time: "12:00" },
    { time: "11:00" },
    { time: "16:00" },
    { time: "15:00" },
    { time: "14:00" },
    { time: "19:00" },
    { time: "18:00" },
    { time: "17:00" },
    { time: "20:00" },
  ];

  const handleDateChange = (date) => {
    setValue(date);
    setShowPopState(true); // Set pop state true on date selection
  };

  const handleButtonClick = (buttonName) => {
    setActiveButtonmet(buttonName);
    navigate("/bookameeting");
  };

  const bookmeeting = () => {
    setSubmitAttempt(true);
    if (descraption && value && activeItem) {
      navigate("/success");
      setError("");
      setSuccessAction("/");
    } else {
      // navigate("/error");
      setError("");
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <div className={`readanalysisTopDiv ${fadeOut ? "fade-out" : ""}`}>
      <Navbar />

      <div className="readanalysismaindiv">
        <div className="readanalysisbuttonmaindiv">
          <button className={"active2"}>قراءة تحليل</button>

          <button
            className={"simplebuttomn"}
            onClick={() => handleButtonClick("لقاء مع الدكتور أونلاين")}
          >
            لقاء مع الدكتور أونلاين
          </button>
        </div>

        {activeButtonmet === "قراءة تحليل" && (
          <>
            <div className="textreadanalysis">
              <h2>طلب تحليل</h2>
              <h1>لا تتردد في طلب تحليلك</h1>
            </div>

            <div className="readanalysisformmaindiv">
              <div>
                <p>عنوان تذكرة التحليل</p>
                <input
                  type="text"
                  name="title"
                  value={title}
                  onChange={handleChange}
                  className={submitAttempt && !title ? "input-error" : ""}
                />

                <p>الوصف</p>
                <textarea
                  name="description"
                  value={description}
                  onChange={handleChange}
                  className={submitAttempt && !description ? "input-error" : ""}
                ></textarea>
              </div>
            </div>

            <div className="readanalysisbtnsmaindiv">
              <div>
                <h3>ارفع تحليلك</h3>
              </div>

              <div className="uploadfileanlysis">
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  multiple // Allow multiple files
                  accept=".pdf, image/*, .heic" // Accept only image files
                />
                <button
                  className="custom-file-upload"
                  onClick={handleFileInputClick}
                  style={{
                    background:
                      files.length > 0
                        ? "#B571AC"
                        : "" || (submitAttempt && files.length === 0 && "red"),
                  }}
                >
                  {files.length > 0 ? "تم الرفع" : "رفع ملف التحليل"}
                </button>
                {alert ? (
                  <div style={{ color: "red", fontSize: "18px" }}>
                    تنسيق pdf غير مدعوم
                  </div>
                ) : (
                  <></>
                )}
              </div>

              {files.length > 0 && (
                <div className="file-names">
                  {Array.from(files).map((file, index) => (
                    <div key={index}>{file.name}</div>
                  ))}
                </div>
              )}
            </div>

            <div className="radioandtextmaindiv">
              <p className={submitAttempt && !selectedOption ? "colorred" : ""}>
                قراءة تحليل (500 ريال)
              </p>
              <input
                type="radio"
                name="selectedOption"
                value="500.00"
                checked={selectedOption === "500.00"}
                onChange={handleChange}
              />
            </div>

            <div className="radioandtextmaindiv">
              <p className={submitAttempt && !selectedOption ? "colorred" : ""}>
                قراءة تحليل +تصميم نظام غذائي(1000 ريال)
              </p>
              <input
                type="radio"
                name="selectedOption"
                value="1000.00"
                checked={selectedOption === "1000.00"}
                onChange={handleChange}
              />
            </div>

            <div className="Analysisrequestbtn">
              <button onClick={handleSubmit}>طلب تحليل</button>
            </div>
          </>
        )}

        {activeButtonmet === "لقاء مع الدكتور أونلاين" && (
          <div className="calendermaindiv">
            <Calendar onChange={handleDateChange} value={value} />
            {showPopState && (
              <>
                <div className="showlinediv">المواعيد المتاحة</div>

                <div className="datesselectmain">
                  {scheduleData.map((schedule, index) => (
                    <button
                      key={index}
                      // className={activeItem === schedule.time ? "activatetim" : ""}
                      className={
                        (submitAttempt && !activeItem) ||
                        activeItem === schedule.time
                          ? activeItem === schedule.time
                            ? "activatetim"
                            : "input-error"
                          : ""
                      }
                      onClick={() => handleClick(schedule.time)}
                    >
                      {schedule.time}
                    </button>
                  ))}
                </div>
                <div className="textdescraption">
                  <label htmlFor="">الوصف</label>
                  <textarea
                    name="descraption"
                    value={descraption}
                    onChange={(e) => setDescraption(e.target.value)}
                    className={
                      submitAttempt && !descraption ? "input-error" : ""
                    }
                  ></textarea>
                </div>
                <div className="Analysisrequestbtn">
                  <button onClick={bookmeeting}>طلب لقاء</button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <div className="readanalysisfooter">
        <Footer />
      </div>
    </div>
  );
}
