import React from 'react'
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';

function Usingpolicy() {
  return (
    <>
    <div className="ownermaindiv">
<div className="ownernavbar">
    <Navbar/>
</div>

<div className="ownerconatainermain">
    <ol>
   <div className='headingdivpol'>سياسة الاستخدام </div>
   <li className='headingdivpol'>الشروط
   </li>
   <div className='paragraphdivpol'>من خلال الوصول إلى هذه الأكاديمية، فإنك توافق على الالتزام بشروط الاستخدام هذه، وجميع القوانين واللوائح المعمول بها، وتوافق على أنك مسؤول عن الامتثال لأي قوانين محلية معمول بها. إذا كنت لا توافق على أي من هذه الشروط، فيحظر عليك استخدام أو الوصول إلى هذا الموقع. المواد الواردة في هذه الأكاديمية محمية بموجب قانون حقوق النشر والعلامات التجارية المعمول به.</div>

   <li className='headingdivpol'>
   ترخيص الاستخدام :</li>
   <div className='paragraphdivpol'>
    <ul>
        <li>تعديل أو نسخ المواد</li>
        <li>استخدام المواد لأي غرض تجاري ، أو لأي عرض عام (تجاري أو غير تجاري)</li>
        <li>محاولة فك أو عكس هندسة أي برنامج موجود على موقع الأكاديمية على الويب .</li>
        <li>إزالة أي حقوق نشر أو تدوينات ملكية أخرى من المواد ؛ أو نقل المواد إلى شخص آخر أو "نسخ" المواد الموجودة على أي خادم آخر. ينتهي هذا الترخيص تلقائيًا إذا انتهكت أيًا من هذه القيود وقد يتم إنهاؤه بواسطة الشركة في أي وقت. عند إنهاء عرضك لهذه المواد أو عند إنهاء هذا الترخيص ، يجب عليك تدمير أي مواد تم تنزيلها في حوزتك سواء في شكل إلكتروني أو مطبوع.</li>
    </ul>
   </div>
   <li className='headingdivpol'>
   إخلاء المسؤولية</li>
   <div className='paragraphdivpol'>يتم توفير المواد الموجودة على موقع الأكاديمية "كما هي". لا تقدم الأكاديمية أي ضمانات ، صريحة أو ضمنية ، وتخلي مسؤوليتها وتبطل بموجبها جميع الضمانات الأخرى ، بما في ذلك على سبيل المثال لا الحصر ، الضمانات الضمنية أو شروط القابلية للتسويق أو الملاءمة لغرض معين أو عدم التعدي على الملكية الفكرية أو أي انتهاك آخر للحقوق. علاوة على ذلك ، لا تضمن الأكاديمية أو تقدم أي إقرارات تتعلق بالدقة أو النتائج المحتملة أو موثوقية استخدام المواد الموجودة على موقعها على الويب أو المتعلقة بهذه المواد أو على أي مواقع مرتبطة بهذا الموقع. .</div>
   <li className='headingdivpol'>
    القيود
    </li>
   <div className='paragraphdivpol'>
   لن تكون الأكاديمية بأي حال من الأحوال مسؤولة عن أي أضرار (بما في ذلك ، على سبيل المثال لا الحصر ، الأضرار الناجمة عن فقدان البيانات أو الربح ، أو بسبب انقطاع الأعمال) الناشئة عن استخدام أو عدم القدرة على استخدام المواد الموجودة على موقع الأكاديمية، حتى لو كان تم إخطار الأكاديمية أو أحد المفوضين من الأكاديمية شفهياً أو خطياً بإمكانية حدوث مثل هذا الضرر. نظرًا لأن بعض الولايات القضائية لا تسمح بفرض قيود على الضمانات الضمنية أو قيود المسؤولية عن الأضرار التبعية أو العرضية ، فقد لا تنطبق هذه القيود عليك.

   </div>


   <li className='headingdivpol'> التنقيحات</li>
   <div className='paragraphdivpol'>قد تحتوي المواد التي تظهر على موقع الأكاديمية على أخطاء فنية أو مطبعية أو فوتوغرافية. لا تضمن الأكاديمية أن أيًا من المواد الموجودة على موقع الويب الخاص بها دقيقة أو كاملة أو حديثة. يجوز للأكاديمية إجراء تغييرات على المواد الواردة في موقع الويب الخاص بها في أي وقت دون إشعار مسبق. ومع ذلك ، لا تقدم الأكاديمية أي التزام بتحديث المواد.</div>

   <li className='headingdivpol'> الروابط</li>
   <div className='paragraphdivpol'>لم تقم الأكاديمية بمراجعة جميع المواقع المرتبطة بموقعها على الويب وليست مسؤولة عن محتويات أي موقع مرتبط من هذا القبيل. إن تضمين أي رابط لا يعني موافقة الأكاديمية على الموقع. استخدام أي موقع مرتبط يكون على مسؤولية المستخدم الخاصة.</div>

   <li className='headingdivpol'>القانون الحاكم</li>
   <div className='paragraphdivpol'>تخضع أي مطالبة تتعلق بالموقع الإلكتروني للأكاديمية لقوانين السلطة القضائية المحلية لمالك الأكاديمية بغض النظر عن تعارضها مع أحكام القانون.</div>

   </ol>
</div>

<div className="ownerfooter">
    <Footer/>
</div>

    </div>
    
    
    </>
  )
}

export default Usingpolicy;