import React, { useState, useEffect, useContext } from 'react';
import Footer from '../../User/Footer/Footer';
import { Icon } from '@iconify/react/dist/iconify.js';
import bookimg from '../../../Asserts/Images/book.svg';
import nobook from '../../../Asserts/Images/nobook.png';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../../../context/UserContext';
import Loading from '../../User/Loading/Loading';
import { useSessionErrorHandler } from '../../User/Managesession';

export default function BookUploaded({ setChangetab,changetab, handleBookHideController, section, subSection }) {
  const { loading, token, user, setSuccessAction, setSuccess } = useContext(UserContext);
  const handleSessionError = useSessionErrorHandler();
  const [bookloading, setBookLoading] = useState(true);
  const [deleteId, setDeleteId] = useState(null);
  const [books, setBooks] = useState([]);
  const [popupshow, setPopupshow] = useState(null);
  const [deletepopup, setDeletepopup] = useState(false);
  const [pagination,setPagination] = useState(1)
    const [paginationcontrol,setPaginationcontrol] = useState(1)
  const [activeButton2, setActiveButton2] = useState('');
  const [subcategories, setSubCategories] = useState([]);
  const [courses, setCourses] = useState([]);
  const [deleteReason,setDeleteReason] = useState('');
  const [bookPagination, setBookPagination] = useState(1);
  const [totalBookPages, setTotalBookPages] = useState(0);
    const navigate = useNavigate();
    const bookPaginationIncrement = () => {
      if (bookPagination < totalBookPages) {
        setBookPagination(bookPagination + 1);
       // Fetch new books for the next page
      }
    };

    const bookPaginationDecrement = () => {
      if (bookPagination > 1) {
        setBookPagination(bookPagination - 1);
       // Fetch new books for the previous page
      }
    };

    useEffect(() => {
      getAllBooks(subSection);
    }, [bookPagination]);


  const axiosInstance = axios.create({
    withCredentials: true,
  });

  const getAllBooks = async (subSection) => {
    setBookLoading(true);
    try {
      const res = await axiosInstance.get(`${process.env.REACT_APP_SERVER_URL}/book/getAllBooksBySectionAndSubSection/${'الكتب'}/${subSection}?page=${bookPagination}&limit=3`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      console.log('Books:', res.data);
      setBooks(res.data.response);

      setBookPagination(bookPagination);
      setTotalBookPages(res.data.totalPages)
      setPaginationcontrol(Math.ceil(res.data.totalBooks / 10)); // Set pagination control based on the total number of books
    } catch (err) {
      console.log('Error fetching books:', err);
      setBooks([]);
      handleSessionError(err);
    } finally {
      setBookLoading(false);  // Set to false after the data has been fetched or an error occurs
    }
  };
  const fetchBooks = async (endpoint) => {
    try {
      setBookLoading(true)
      const response = await axios.get(endpoint, {
        headers: { authorization: `Bearer ${token}` },
      });
      setCourses(response.data.response);
      setBookLoading(false)
      console.log('Books',response.data.response)
    } catch (err) {
      console.error(err);
      setBookLoading(false)
      handleSessionError(err);
    }
  };

  useEffect(() => {
    if (changetab === "مخفي") {
      fetchBooks(
        `${process.env.REACT_APP_SERVER_URL}/book/allhiddenBooks?section=الكتب&subSection=${subSection}`
      );
    } else {
getAllBooks(subSection)
    }
  }, [activeButton2,section, changetab]);

  const gotobuybook = (bookId) => {
    navigate(`/buybook/${bookId}`);
  };

  const showpopup = (e, index) => {
    e.stopPropagation();
    setPopupshow(popupshow !== index ? index : null);
  };

  const showdelete = (e, deleteId) => {
    e.stopPropagation();
    setDeleteId(deleteId);
    setDeletepopup(!deletepopup);
  };

  const performDeleteAction = async () => {
    setDeletepopup(false);
    setDeleteId(null)
    try {
      await axios.delete(`${process.env.REACT_APP_SERVER_URL}/book/delete/${deleteId}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
        data: {
          reason: deleteReason,
        },
      });
      getAllBooks(subSection);
    } catch (err) {
      console.log('Error deleting book:', err);
      handleSessionError(err);
      getAllBooks(subSection);
    }
  }

  const unhideStatus = async (e, bookID) => {
    setBookLoading(true)
    e.stopPropagation();
    try {
      await axios.patch(`${process.env.REACT_APP_SERVER_URL}/book/updateBookFromHiddenToShow/${bookID}`, {}, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      handleBookHideController(subSection);
    } catch (err) {
      console.log('Error unhiding book:', err);
      handleBookHideController(subSection);
    }
    setPopupshow(null)
    setBookLoading(false)
  };

  const navigateSuccess = async (e, bookID) => {
    e.stopPropagation();
    try {
      await axios.patch(`${process.env.REACT_APP_SERVER_URL}/book/updateBookToHidden/${bookID}`, {}, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setSuccessAction("/");
      setSuccess("Success message");
      getAllBooks(subSection);
    } catch (err) {
      console.log('Error hiding book:', err);
      getAllBooks(subSection);
    }
    setPopupshow(null)
  };

  const gotouploadbook = (e, id) => {
    e.stopPropagation();
    navigate(`/uploadbook/${id}`);
  };

  return (
    bookloading ? <div className='LoadingInFetchingBooksContainer'>
    <div className='LoadingInFetchingBooks'>
 <Icon icon="eos-icons:bubble-loading" width="1.2em" height="1.2em" />
    </div>
  </div> :
    <div className='booktopmaidiv'>
      {deletepopup && (
        <div className="deletemaindiv">
          <div className="deletecontainer">
          <div className="deletetitle">
                <div>سبب الحذف</div>
                <div className="CloseHadafPopup" onClick={()=>setDeletepopup(false)}>x</div>
              </div>
              <textarea
        placeholder='مختلف عن موضوع القسم'
        value={deleteReason}
        onChange={e => setDeleteReason(e.target.value)}
      />
            <button onClick={performDeleteAction}>
              <Icon icon="ic:baseline-delete" width="1.2em" height="1.2em" /> حذف
            </button>
          </div>
        </div>
      )}

      {changetab !== "مخفي" ?
        books?.length > 0 && !bookloading ? (
          <>{
          books.map((book, index) => (
            <div className="mainbookdiv" onClick={() => gotobuybook(book.id)} key={book.id}>
              <img src={book.bookCoverPhoto} alt="" className='book-img' />
              <div className="paddingleftbotm">
                <h2>{book.bookTitle}</h2>
                {user && (user.role === "admin" || user.role === "super-admin" ) && (
                  <>
                    <div className='threedotsbookicon'>
                      <Icon onClick={(e) => showpopup(e, index)} icon="bi:three-dots-vertical" width="1.2em" height="1.2em" />
                    </div>
                    {popupshow === index && (
                      <div className="popupmain bookpopupmaindiv">
                        <div onClick={(e) => gotouploadbook(e, book._id)}>تعديل</div>
                        {book.hidden ? <div onClick={(e) => unhideStatus(e, book._id)}>إظهار</div> : <div onClick={(e) => navigateSuccess(e, book._id)}>إخفاء</div>}
                        <div onClick={(e) => showdelete(e, book._id)}>حذف</div>
                      </div>
                    )}
                  </>
                )}
                <p>من تأليف <span>{book.author}</span></p>
                <p>{book.book_description?.substring(0, 150)}</p>
                <h5>{book.bookPrice} SR</h5>
              </div>
            </div>
          ))
        }
        { <div className="pagination-container">
        <div className={bookPagination === 1 ? 'pagination-disable' : 'pagination-enable'} onClick={bookPaginationDecrement}>
          {'<'}
        </div>
        <div>
          <span className="pagination-active">{bookPagination}</span>
        </div>
        <div className={bookPagination === totalBookPages ? 'pagination-disable' : 'pagination-enable'} onClick={bookPaginationIncrement}>
          {'>'}
        </div>
      </div>}
        </>
        ) : (
          <div className="nobooksmaindiv">
            {/* use svg instead of img */}
            <img src={nobook} alt="" />
            لم تقم بشراء أي كتاب
          </div>
        )
      : null}

      {changetab === "مخفي" && (
        courses?.length > 0 ? (
          <>{
          courses.map((book, index) => (
            <div className="mainbookdiv" onClick={() => gotobuybook(book.id)} key={book.id}>
              <img src={bookimg} alt="" />
              <div className="paddingleftbotm">
                <h2>{book.bookTitle}</h2>
                {user && (user.role === "admin"||user.role === "super-admin") && (
                  <>
                    <div className='threedotsbookicon'>
                      <Icon onClick={(e) => showpopup(e, index)} icon="bi:three-dots-vertical" width="1.2em" height="1.2em" />
                    </div>
                    {popupshow === index && (
                      <div className="popupmain bookpopupmaindiv">
                        <div onClick={(e) => gotouploadbook(e, book._id)}>تعديل</div>
                        {book.hidden ? <div onClick={(e) => unhideStatus(e, book._id)}>إظهار</div> : <div onClick={(e) => navigateSuccess(e, book._id)}>إخفاء</div>}
                        <div onClick={(e) => showdelete(e, book._id)}>حذف</div>
                      </div>
                    )}
                  </>
                )}
                <p>من تأليف <span>{book.author}</span></p>
                <p>{book.book_description?.substring(0, 150)}</p>
                <h5>{book.bookPrice} SR</h5>
              </div>
            </div>
          ))
        }

        </>
        ) : (
          <div className="nobooksmaindiv">
            <img src={nobook} alt="" />
            لم تقم بشراء أي كتاب
          </div>
        )
      )}
    </div>
  );
}