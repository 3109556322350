import React, { useState,useEffect } from 'react';
import './Meetingnotify.css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../../context/UserContext';
import Navbar from '../../User/Navbar/Navbar';
import Footer from '../../User/Footer/Footer';
import axios from 'axios';
import Loading from '../../User/Loading/Loading';
import norequest from '../../../Asserts/Images/norequest.png'
import { useSessionErrorHandler } from '../../User/Managesession';
import BranchLoading from '../../User/branchLoading/BranchLoading';

function Meetingnotify() {

  const  handleSessionError  = useSessionErrorHandler();

const navigate = useNavigate();

const {user,token,loading,selectedTab,setSelectedTab,setLoading ,subTab,setSubTab,activeTab,setActiveTab} = useContext(UserContext);
const [subTab2, setSubTab2] = useState(subTab);

  // const [selectedTab, setSelectedTab] = useState('اللقاءات المحجوزة');
  const [meetings,setMeetings] = useState([{title:"dummy"}])
const [showmetings, setShowmetings] = useState(subTab)
const [endmetings, setEndmetings] = useState("اليوم")

const [bookPagination, setBookPagination] = useState(1);
  const [totalBookPages, setTotalBookPages] = useState(0);

  const bookPaginationIncrement = () => {
    if (bookPagination < totalBookPages) {
      setBookPagination(bookPagination + 1);
     // Fetch new books for the next page
    }
  };

  const bookPaginationDecrement = () => {
    if (bookPagination > 1) {
      setBookPagination(bookPagination - 1);
     // Fetch new books for the previous page
    }
  };

// useState(()=>{
//   const getendmeetings = localStorage.getItem("endmeetings");
//   return getendmeetings ? getendmeetings : "اليوم";
// })
const getAllMeetingsBystatus = async (status, page = 1) => {
  try {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/meeting/getMeetingByStatus?meeting_status=${status}&page=${page}&limit=10`,
        { headers: { authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          setMeetings(response.data.response);
          setTotalBookPages(response.data.totalPages);  // Set total pages from the response
          setBookPagination(response.data.currentPage);  // Set current page from the response
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        handleSessionError(error);
        setMeetings([]);
      });
  } catch (error) {
    setMeetings([]);
    setLoading(false);
    handleSessionError(error);
    console.log(error);
  }
};


const getTodayBookedMeetings = async(status,page) =>{
  console.log(status)
  try{
    setLoading(true)
    await axios.get(`${process.env.REACT_APP_SERVER_URL}/meeting/getMeetingByStatusAndTodayBookedMeetings?meeting_status=${status}&page=${page}&limit=10`,{
      headers:{
        authorization:`Bearer ${token}`
      }
    }).then((response)=>{
      console.log(response)
      setMeetings(response.data.response)
      setLoading(false)
      setBookPagination(response.data.currentPage)
      setTotalBookPages(response.data.totalPages)
    }).catch((error)=>{
      console.log(error)
      setMeetings([])
      setLoading(false)
      handleSessionError(error);
    })

  }catch(err){
    console.log(err)
    setMeetings([])
    setLoading(false)
    handleSessionError(err);


  }

}



useEffect(() => {
  setActiveTab('تحليلات')
  localStorage.setItem("showmeetings" , showmetings);
  localStorage.setItem("endmeetings" , endmetings)
  if(subTab==='اليوم'){
    getTodayBookedMeetings(selectedTab,bookPagination)
    // getAllMeetingsBystatus(selectedTab)
  }
  else{
    // getTodayBookedMeetings(selectedTab)
  getAllMeetingsBystatus(selectedTab,bookPagination)
  }
}, [showmetings,subTab,subTab2,endmetings,selectedTab,bookPagination])


const [date, setDate] = useState(new Date());

const handleDateChange = (newDate) => {
  setDate(newDate);
  console.log(newDate)
};
  const tabs = [
    'اللقاءات المحجوزة',
    'لقاءات غير مدفوعة',
    'اللقاءات المعلقة',
    'اللقاءات المنتهية',
    'اللقاءات المرفوضة',
  ];

  const tabStyles = {
    'اللقاءات المحجوزة': {
      active: { backgroundColor: '#B571AC', color: '#FFFFFF', },
      inactive: { backgroundColor: '#FFFFFF', color: '#B571AC' }
    },
    'اللقاءات المعلقة': {
      active: { backgroundColor: '#B571AC', color: '#FFFFFF' },
      inactive: { backgroundColor: '#FFFFFF', color: '#B571AC' }
    },
    'اللقاءات المنتهية': {
      active: { backgroundColor: '#B571AC', color: '#FFFFFF' },
      inactive: { backgroundColor: '#FFFFFF', color: '#B571AC' }
    },
    'لقاءات غير مدفوعة': {
      active: { backgroundColor: '#B571AC', color: '#FFFFFF' },
      inactive: { backgroundColor: '#FFFFFF', color: '#B571AC' }
    },
    'اللقاءات المرفوضة': {
        active: { backgroundColor: '#B571AC', color: '#FFFFFF' },
        inactive: { backgroundColor: '#FFFFFF', color: '#B571AC' }
      }
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    setSubTab('اليوم');
    setSubTab2('اليوم');
    setShowmetings('اليوم')
    setEndmetings('اليوم')
    // Reset the subTab when changing the main tab
  };

  const handleTabClick2 = (tab) => {
    setActiveTab(tab);
    setSubTab('اليوم');
    setSubTab2('اليوم');
    setShowmetings('اليوم')
    setEndmetings('اليوم')
    // Reset the subTab when changing the main tab
  };


const gotomymeetings = (meeting_id)=>{
  navigate(`/Interviewdetail/${meeting_id}`);
}


  const subtabs = ['اليوم', 'الكل'];
  const subtabs2 = ['اليوم', 'الكل']

  const subtabStyles = {
    'اليوم': {
      active: { backgroundColor: '#B571AC', color: '#FFFFFF' },
      inactive: { backgroundColor: '#FFFFFF', color: '#B571AC' }
    },
'الكل': {
      active: { backgroundColor: '#B571AC', color: '#FFFFFF' },
      inactive: { backgroundColor: '#FFFFFF', color: '#B571AC' }
    }
  };

  const subtabStyles2 = {
    'اليوم': {
      active: { backgroundColor: '#B571AC', color: '#FFFFFF' },
      inactive: { backgroundColor: '#FFFFFF', color: '#B571AC' }
    },
'الكل': {
  active: { backgroundColor: '#B571AC', color: '#FFFFFF' },
  inactive: { backgroundColor: '#FFFFFF', color: '#B571AC' }
    }
  };


  const handlesubTabClick = (tab) => {
    setSubTab(tab);
    setSubTab2(tab);
    setShowmetings(tab)
    setEndmetings(tab)
  };

  const handlesubTabClick2 = (tab) => {
    setSubTab2(tab);
    setSubTab(tab);
    setEndmetings(tab)
    setShowmetings(tab)
  };

  const dynamicDivStyle = subTab ? subtabStyles[subTab].active : {};

const gototests = ()=>{
  setActiveTab('لقاءات')
  navigate("/myanalyticsadmain")
}


  return (
    <>

<div className="meetingmaindiv">

<div className="meetingnavbar">
<Navbar/>
</div>



<div className="navigationbuttons">
            <div
              className={` ${activeTab === 'تحليلات' ? 'activebuttondiv' : 'unactiveclass'}`}
              onClick={() => handleTabClick2('تحليلات')}
            >
              اللقاءات

            </div>
            <div
              className={` ${activeTab === 'لقاءات' ? 'activebuttondiv' : 'unactiveclass'}`}
              onClick={() => gototests()}
            >
              التحليلات

            </div>
          </div>

      <div className="meetingnotifymaindiv">
      <div className="buttonsmaindiv">
          {tabs.map((tab) => (
            <div
              key={tab}
              style={selectedTab === tab ? tabStyles[tab].active : tabStyles[tab].inactive}
              onClick={() => handleTabClick(tab)}
              className="tabButton"
            >
              {tab}
            </div>
          ))}
        </div>
        {selectedTab === 'اللقاءات المحجوزة' && (
          <div className="subbuttonsmaindiv">
            <div onClick={() => handlesubTabClick('اليوم')} className={showmetings === 'اليوم' && "activemeetings"}>اليوم</div>
            <div className={showmetings === 'الكل' && "activemeetings"} onClick={() => handlesubTabClick('الكل')}>الكل</div>
            <div className={showmetings === 'النتيجة' && "activemeetings"} onClick={() => setShowmetings('النتيجة')}>النتيجة</div>
          </div>
        )}

        {selectedTab === 'اللقاءات المعلقة' && (
          <div className="yellowsubbuttonsmain">
            {subtabs.map((tab) => (
              <div
                key={tab}
                style={subTab === tab ? subtabStyles[tab].active : subtabStyles[tab].inactive}
                onClick={() => handlesubTabClick(tab)}
                className="tabButton"
              >
                {tab}
              </div>
            ))}
          </div>
        )}


{selectedTab === 'لقاءات غير مدفوعة' && (
          <div className="yellowsubbuttonsmain">
            {subtabs2.map((tab) => (
              <div
                key={tab}
                style={subTab2 === tab ? subtabStyles2[tab].active : subtabStyles2[tab].inactive}
                onClick={() => handlesubTabClick2(tab)}
                className="tabButton"
              >
                {tab}
              </div>
            ))}
          </div>
        )}

        {selectedTab === 'اللقاءات المنتهية' && (
          <div className="greensubbuttonsmain">
               <div onClick={() => handlesubTabClick2('اليوم')} className={endmetings === 'اليوم' && "activemeetingsend"}>اليوم</div>
               <div className={endmetings === 'الكل' && "activemeetingsend"} onClick={() => handlesubTabClick2('الكل')}>الكل</div>
          </div>
        )}
        {
          loading ? <BranchLoading/> : <>






{selectedTab === 'اللقاءات المحجوزة' && <>
{
    showmetings === 'النتيجة' && <>

    <div className="calendar-container">
    <Calendar onChange={handleDateChange} minDate={new Date()}  locale="ar" value={date} />
    </div>
    <div>
    </div>

    </>
}
{
  loading ? <BranchLoading/> : <>{
meetings && meetings.length > 0 ?
        <div className="cardsdivmain">
{
   (showmetings === 'اليوم' || showmetings === 'الكل') ? <>

    {meetings.map((meeting, index) => {
      const date = new Date(meeting.Meeting_Time);

      // Format the date as "day/month/year"
      const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

      // Format the time as "hours:minutes"
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const formattedTime = `${hours % 12 || 12}:${minutes < 10 ? '0' : ''}${minutes}`;

      // Determine AM or PM in Arabic
      const amPm = hours >= 12 ? 'مساء' : 'صباحا';

      return (
  <div className="notificationcardsmain" onClick={()=>gotomymeetings(meeting._id)}>

        <div key={index} className="notificationcardleft addmeetingclass">
          <div>{showmetings === "اليوم" && "اليوم"} {showmetings === "الكل" && meeting.day}</div>
          <div>{formattedDate}</div>
          <div>{formattedTime} {amPm}</div>
        </div>
        <div className="notificatoncardright">
        <div className="noticardtopdiv">
          <div className="notiardtopleft">{meeting && meeting.Meeting_Req_User&& meeting?.Meeting_Req_User?.phone_number}</div>
          <div className="noticardtopright">
            <div>{meeting && meeting.Meeting_Req_User&& meeting?.Meeting_Req_User?.first_name+" "+meeting?.Meeting_Req_User?.last_name}</div>
            {/* <div>{meeting topic will be here}</div> */}
            <div className="givepurplecolor">{meeting?.Meeting_Day} {formattedDate}</div>
          </div>
        </div>

        <div className="noticardbottomdiv">
         {meeting?.Meeting_description}
        </div>
      </div>
      </div>
      );
    })}
{
        meetings && meetings.length > 0 ? <div className="pagination-container">
        <div className={bookPagination === 1 ? 'pagination-disable' : 'pagination-enable'} onClick={bookPaginationDecrement}>
          {'<'}
        </div>
        <div>
          <span className="pagination-active">{bookPagination}</span>
        </div>
        <div className={bookPagination === totalBookPages ? 'pagination-disable' : 'pagination-enable'} onClick={bookPaginationIncrement}>
          {'>'}
        </div>
      </div> : <></>
      }

          </> : <></>
}
        </div>: <>
{
  showmetings !== 'النتيجة' ? <><div className="norequestmaindiv">
  <img src={norequest} alt="" />
  لم تقم بحجز أي مواعيد
  </div></>:<></>
}


</>}</>}

        </>}

        {
          loading ? <BranchLoading/> : <>{
        selectedTab === 'اللقاءات المعلقة'  && (
    <div className="cardsdivmain">
      {meetings && meetings.length > 0 ? (

        meetings.map((meeting, index) => {
          const date = new Date(meeting.Meeting_Time);
          const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
          const hours = date.getHours();
          const minutes = date.getMinutes();
          const formattedTime = `${hours % 12 || 12}:${minutes < 10 ? '0' : ''}${minutes}`;
          const amPm = hours >= 12 ? 'مساء' : 'صباحا';

          return (
            <div className="notificationcardsmain" key={index} onClick={()=>gotomymeetings(meeting._id)}>
              <div className="notificationcardleft addmeetingclass" style={dynamicDivStyle}>
                <div>{meeting?.Meeting_Day}</div>
                <div>{formattedDate}</div>
                <div>{formattedTime} {amPm}</div>
              </div>
              <div className="notificatoncardright">
                <div className="noticardtopdiv">
                  <div className="notiardtopleft">{meeting && meeting.Meeting_Req_User&& meeting?.Meeting_Req_User?.phone_number}</div>
                  <div className="noticardtopright">
                    <div>{`${meeting && meeting.Meeting_Req_User&& meeting?.Meeting_Req_User?.first_name} ${meeting && meeting?.Meeting_Req_User&& meeting?.Meeting_Req_User?.last_name}`}</div>
                    {/* <div>{meeting?.Meeting_description}</div> */}
                    <div className="givepurplecolor">{`${meeting?.Meeting_Day} ${formattedDate}`}</div>
                  </div>
                </div>
                <div className="noticardbottomdiv">
                  {meeting.Meeting_description}
                </div>
              </div>
            </div>
          );
        })


      ) : (
        <>

<div className="norequestmaindiv">
<img src={norequest} alt="" />
لم تقم بحجز أي مواعيد
</div>

</>
      )}
      {
        meetings && meetings.length > 0 ?
        <div className="pagination-container">
        <div className={bookPagination === 1 ? 'pagination-disable' : 'pagination-enable'} onClick={bookPaginationDecrement}>
          {'<'}
        </div>
        <div>
          <span className="pagination-active">{bookPagination}</span>
        </div>
        <div className={bookPagination === totalBookPages ? 'pagination-disable' : 'pagination-enable'} onClick={bookPaginationIncrement}>
          {'>'}
        </div>
      </div> : <></>
      }
    </div>
  )}</>}


   {
   selectedTab ==='لقاءات غير مدفوعة' && (
    <div className="cardsdivmain">
      {meetings && meetings.length > 0 ? (
        meetings.map((meeting, index) => {
          const date = new Date(meeting.Meeting_Time);
          const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
          const hours = date.getHours();
          const minutes = date.getMinutes();
          const formattedTime = `${hours % 12 || 12}:${minutes < 10 ? '0' : ''}${minutes}`;
          const amPm = hours >= 12 ? 'مساء' : 'صباحا';

          return (
            <div className="notificationcardsmain" key={index} onClick={()=>gotomymeetings(meeting._id)}>
              <div className="notificationcardleft addmeetingclass" style={dynamicDivStyle}>
                <div>{meeting?.Meeting_Day}</div>
                <div>{formattedDate}</div>
                <div>{formattedTime} {amPm}</div>
              </div>
              <div className="notificatoncardright">
                <div className="noticardtopdiv">
                  <div className="notiardtopleft">{meeting && meeting?.Meeting_Req_User&& meeting?.Meeting_Req_User?.phone_number}</div>
                  <div className="noticardtopright">
                    <div>{`${meeting && meeting.Meeting_Req_User&& meeting?.Meeting_Req_User?.first_name} ${meeting && meeting?.Meeting_Req_User&& meeting?.Meeting_Req_User?.last_name}`}</div>
                    <div className="givepurplecolor">{meeting?.Meeting_Day} {formattedDate}</div>
                  </div>
                </div>
                <div className="noticardbottomdiv">
                  {meeting?.Meeting_description}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <>

        <div className="norequestmaindiv">
        <img src={norequest} alt="" />
        لم تقم بحجز أي مواعيد
        </div>

        </>
      )}
      {
        meetings && meetings.length > 0 ?
        <div className="pagination-container">
        <div className={bookPagination === 1 ? 'pagination-disable' : 'pagination-enable'} onClick={bookPaginationDecrement}>
          {'<'}
        </div>
        <div>
          <span className="pagination-active">{bookPagination}</span>
        </div>
        <div className={bookPagination === totalBookPages ? 'pagination-disable' : 'pagination-enable'} onClick={bookPaginationIncrement}>
          {'>'}
        </div>
      </div> : <></>
      }
    </div>
  )}

  {
  selectedTab === 'اللقاءات المنتهية' && (
    <div className="cardsdivmain">
      {meetings && meetings.length > 0 ? (
        meetings.map((meeting, index) => {
          const date = new Date(meeting.Meeting_Time);
          const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
          const hours = date.getHours();
          const minutes = date.getMinutes();
          const formattedTime = `${hours % 12 || 12}:${minutes < 10 ? '0' : ''}${minutes}`;
          const amPm = hours >= 12 ? 'مساء' : 'صباحا';

          return (
            <div className="notificationcardsmain" key={index} onClick={()=>gotomymeetings(meeting._id)}>
              <div className="notificationcardleft addmeetingclass addcolorclass">
              <div>{meeting?.Meeting_Day}</div>
                <div>{formattedDate}</div>
                <div>{formattedTime} {amPm}</div>
              </div>
              <div className="notificatoncardright">
                <div className="noticardtopdiv">
                  <div className="notiardtopleft">{meeting.Meeting_Req_User?.phone_number}</div>
                  <div className="noticardtopright">
                    <div>{`${meeting && meeting.Meeting_Req_User&&meeting.Meeting_Req_User.first_name} ${ meeting && meeting?.Meeting_Req_User&& meeting?.Meeting_Req_User?.last_name}`}</div>

                    <div className="givepurplecolor">{`${meeting?.Meeting_Day} ${formattedDate}`}</div>
                  </div>
                </div>
                <div className="noticardbottomdiv">
                  {meeting.Meeting_description}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <>

<div className="norequestmaindiv">
<img src={norequest} alt="" />
لم تقم بحجز أي مواعيد
</div>

</>
      )}
      {
        meetings && meetings.length > 0 ?
        <div className="pagination-container">
        <div className={bookPagination === 1 ? 'pagination-disable' : 'pagination-enable'} onClick={bookPaginationDecrement}>
          {'<'}
        </div>
        <div>
          <span className="pagination-active">{bookPagination}</span>
        </div>
        <div className={bookPagination === totalBookPages ? 'pagination-disable' : 'pagination-enable'} onClick={bookPaginationIncrement}>
          {'>'}
        </div>
      </div> : <></>
      }
    </div>
  ) }

  {
  selectedTab === 'اللقاءات المرفوضة' && (
    <div className="cardsdivmain">
      {meetings && meetings.length > 0 ? (
        meetings.map((meeting, index) => {
          const date = new Date(meeting.Meeting_Time);
          const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
          const hours = date.getHours();
          const minutes = date.getMinutes();
          const formattedTime = `${hours % 12 || 12}:${minutes < 10 ? '0' : ''}${minutes}`;
          const amPm = hours >= 12 ? 'مساء' : 'صباحا';

          return (
            <div className="notificationcardsmain" key={index} onClick={()=>gotomymeetings(meeting._id)}>
              <div className="notificationcardleft addmeetingclass addcolorbrown">
              <div>{meeting?.Meeting_Day}</div>
                <div>{formattedDate}</div>
                <div>{formattedTime} {amPm}</div>
              </div>
              <div className="notificatoncardright">
                <div className="noticardtopdiv">
                  <div className="notiardtopleft">{meeting?.Meeting_Req_User?.phone_number}</div>
                  <div className="noticardtopright">
                    <div>{`${meeting && meeting?.Meeting_Req_User&& meeting?.Meeting_Req_User?.first_name} ${meeting && meeting.Meeting_Req_User&& meeting?.Meeting_Req_User?.last_name}`}</div>
                    {/* <div>{meeting?.Meeting_description}</div> */}

                    <div className="givepurplecolor">{`${meeting?.Meeting_Day} ${formattedDate}`}</div>
                  </div>
                </div>
                <div className="noticardbottomdiv">
                  {meeting?.Meeting_description}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <>

<div className="norequestmaindiv">
<img src={norequest} alt="" />
لم تقم بحجز أي مواعيد
</div>

</>

      )}
      {
        meetings && meetings.length > 0 ? <div className="pagination-container">
        <div className={bookPagination === 1 ? 'pagination-disable' : 'pagination-enable'} onClick={bookPaginationDecrement}>
          {'<'}
        </div>
        <div>
          <span className="pagination-active">{bookPagination}</span>
        </div>
        <div className={bookPagination === totalBookPages ? 'pagination-disable' : 'pagination-enable'} onClick={bookPaginationIncrement}>
          {'>'}
        </div>
      </div> : <></>
      }
    </div>
  )}

          </>
        }

      </div>

<div className="meetingfooterdiv">
  <Footer/>
</div>

</div>

    </>
  );
}

export default Meetingnotify;
