import React from 'react'
import { useNavigate } from 'react-router-dom';
import './Mainerror.css'
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';
import { Icon } from '@iconify/react/dist/iconify.js';



function Mainerror() {

    const navigate = useNavigate();

const gotodashboard = ()=>{
    navigate('/')
}

const gotowhatsapp = () => {
  const number = "+966 55 966 6663";
  const encodedNumber = encodeURIComponent(number);
  const url = `whatsapp://send?phone=${encodedNumber}`;
  window.location.href = url;
};

  return (
    <>
    <div className="mainerrormain">

    <div className="whatappicon" onClick={gotowhatsapp}>
                <Icon icon="ic:round-whatsapp" width="1.2em" height="1.2em" />
            </div>


<div className="errorscreennav">
<Navbar/>
</div>

<div className="errorscreencontainer">


<div>404</div>
<div>OOPS ! PAGE NOT FOUND</div>

</div>


<div className="errorscreenfooter">
  <Footer/>
</div>

    </div>
    
    </>
  )
}

export default Mainerror;