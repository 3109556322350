import React, { useEffect, useState, useContext } from "react";
import Navbar from "../../User/Navbar/Navbar";
import Footer from "../../User/Footer/Footer";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../../../context/UserContext";
import { useSessionErrorHandler } from "../../User/Managesession";
import BookUploaded from "./BooksUploaded";

function Videouploading() {
  const [changetab, setChangetab] = useState("عام");
  const [popupshow, setPopupshow] = useState(null);
  const [deletepopup, setDeletepopup] = useState(false);
  const [books, setBooks] = useState([]);

  const { section } = useParams();
  const handleSessionError = useSessionErrorHandler();
  const { user, token, setSuccessAction, setSuccess } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { departmentTitle } = location.state || {};

  const goback = () => {
    navigate(-1);
  };

  const gotouplaodvideo = (e) => {
    e.stopPropagation();
    navigate("/uploadvideo");
  };

  const gotouploadvideos = (subSection) => {
    navigate(`/uploadnewbook/${subSection}`);
  };

  const gotosubcatageries = () => {
    navigate(-1);
  };

  const showpopup = (e, index) => {
    e.stopPropagation();
    setPopupshow(popupshow !== index ? index : null);
  };

  const showdelete = (e) => {
    e.stopPropagation();
    setDeletepopup(!deletepopup);
  };

  const performdeletaction = () => {
    setDeletepopup(false);
    alert("this works");
  };

  const navigatesucess = (e) => {
    e.stopPropagation();
    navigate("/success");
    setSuccessAction("/");
    setSuccess("success message");
  };

 

  const handleTabChange = (tab) => {
    setChangetab(tab);

   
  };

  useEffect(() => {
    handleTabChange(changetab);
  }, [departmentTitle, changetab]);

  const activeButton2 = "الكتب";

  return (
    <>
      <div className="videouploadingmain">
        {deletepopup && (
          <div className="deletemaindiv">
            <div className="deletecontainer">
            <div className="deletetitle">
                <div>سبب الحذف</div>
                <div className="CloseHadafPopup" onClick={()=>setDeletepopup(false)}>x</div>
              </div>
              <textarea placeholder="مختلف عن موضوع القسم"></textarea>
              <button onClick={performdeletaction}>
                <Icon icon="ic:baseline-delete" width="1.2em" height="1.2em" />
                حذف
              </button>
            </div>
          </div>
        )}

        <div className="videouploadingnavbar">
          <Navbar />
        </div>

        <div className="videouplaodingcontainer">
          <div className="videotitlediv">
            <div className="backicondivabso" onClick={goback}>
              <Icon
                icon="ion:chevron-back-outline"
                width="1.2em"
                height="1.2em"
              />
            </div>
            {departmentTitle && departmentTitle}
          </div>

          <div className="navigationback">
            <span onClick={gotosubcatageries}>الأقسام</span>
            <span>{"<"}</span>
            <span>{departmentTitle && departmentTitle}</span>
          </div>

          <div className="navigationbuttonmainbook">
            <div
              onClick={() => handleTabChange("مخفي")}
              className={changetab === "مخفي" ? "navigationactive" : ""}
            >
              مخفي
            </div>
            <div
              onClick={() => handleTabChange("عام")}
              className={changetab === "عام" ? "navigationactive" : ""}
            >
              عام
            </div>
          </div>

          <div
            className="uploadedvideosmaindiv"
            style={{
              background: changetab === "عام" ? "#B571AC" : "transparent",
            }}
          >
            {changetab === "عام" && (
              <div className="uploadingvideobutton">
                <div
                  className="buttondivbackground"
                  onClick={() => gotouploadvideos(departmentTitle)}
                >
                  <Icon icon="ic:round-plus" width="1.2em" height="1.2em" />
                </div>
                <div className="buttondivtitle">إضافة كتاب </div>
              </div>
            )}

            <BookUploaded
              subSection={departmentTitle}
              handleBookHideController={() => handleTabChange("مخفي")}
              changetab={changetab}
             setDeletepopup={setDeletepopup}
             setPopupshow={setPopupshow}
              section={activeButton2}
              setChangetab={setChangetab}
            />
            {/* <Book
              subSection={departmentTitle}
              handleBookHideController={() => handleTabChange("مخفي")}
              changetab={changetab}
              courses={courses}
              section={activeButton2}
            /> */}
          </div>
        </div>

        <div className="videouploadingfooter">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Videouploading;
