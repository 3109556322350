import React, { useEffect, useState } from 'react';
import './myanalytics.css'
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../../context/UserContext';
import Bloodimageanlysis from '../Bloodimageanalysis/Bloodimageanlysis';
import axios from 'axios'
import norequest from '../../../Asserts/Images/norequest.png'
import { useSessionErrorHandler } from '../Managesession';
import Loading from '../Loading/Loading';
export default function Myanalytics() {
  const handleSessionError = useSessionErrorHandler();
  const {isverify,user,loading,setLoading,token} =  useContext(UserContext);
  const navigate = useNavigate();
  const [request_id,setRequest_id] = useState("")

  const [meetings,setMeeting] = useState([])
const [anadetails , setAnadetails] = useState("analytics");

  const goback = (id) => {
    setRequest_id(id)
    navigate(`/patientreqdetails/${id}`)
    // navigate to the interview detail screen with the id
    setAnadetails("analydetails")
  }
  const getRequests = async()=>{
    setLoading(true)
    await axios.get(`${process.env.REACT_APP_SERVER_URL}/patientrequests/status/user/${user._id}`,{
      headers:{
        authorization:`Bearer ${token}`
      }
    }).then((response)=>{
      console.log(response.data)
      setMeeting(response.data.patientRequest)
      setLoading(false)

  }).catch((error)=>{
    handleSessionError(error)
    console.log(error)
    setLoading(false)
  })
}


  useEffect(()=>{
    getRequests()


  },[])

  return (
    loading ? <Loading/> :
    <div>
      <Navbar />

      {
        anadetails === "analytics" && <>


      <div className='mymeetingmaindiv'>


        <div className='meetingtextmain'>
          <h2>تحليلاتي</h2>
        </div>
        {meetings&& meetings.length>0? meetings.map((meeting, index) => {
  // Convert the meeting.date string to a Date object
  const meetingDate = new Date(meeting.created_at);

  // Format the time as hh:mm am/pm
  const timeOptions = { hour: '2-digit', minute: '2-digit' };
  const formattedTime = meetingDate.toLocaleTimeString('en-US', timeOptions);

  // Format the date as dd/mm/yyyy
  const dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const formattedDate = meetingDate.toLocaleDateString('en-GB', dateOptions);

  return (
    <div onClick={() => goback(meeting._id)} key={index} className="maindivofapproval">
      <div className='dateanddaytext'>
        <p>{meeting?.requestDay}</p>
        <p><span>{meeting?.requestTime}</span>  {meeting?.requestDate}</p>
      </div>
      <div className='dronlinetext'>
        <h1>{meeting.request_title}</h1>
      </div>
      <div className='meetingdescriptiontext'>
        <p>{meeting.request_description}</p>
      </div>
    </div>
  );
}):<>

<div className="norequestmaindiv">
<img src={norequest} alt="" />
لم تقم بحجز أي مواعيد
</div>

</>}

      </div>
      </>
      }
  {
        anadetails === "analydetails" &&
        <>
        <Bloodimageanlysis setAnadetails={setAnadetails}   goback={goback}/>
        </>
      }

      <Footer />
    </div>
  );
}
