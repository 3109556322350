import React from 'react'
import './Owner.css'
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

function Owner() {
  return (
    <>
    <div className="ownermaindiv">
<div className="ownernavbar">
    <Navbar/>
</div>

<div className="ownerconatainermain">
   <div className='headingdiv'>عن الأكاديمية</div>
   <div className='headingdiv'>عن أكاديمية مازن
   </div>
   <div className='paragraphdiv'>تأسست أكاديمية د. مازن عوني السقا للعلوم الصحية والتغذوية كنتيجة لجهود متواصلة ومستمرة استمرت لأكثر من ثلاثة عقود في ميدان العمل الأكاديمي والإكلينيكي والتدريب. تجسدت هذه الأطروحة الطويلة الأمد في إنشاء أكاديمية تسعى لتقديم التميز في مجالات العلوم الصحية والتغذوية، مع التركيز على تطوير القدرات البشرية وتعزيز المعرفة العلمية تتميز الأكاديمية بالتركيز على تطوير الخبرات والمهارات اللازمة لمواكبة التحولات السريعة في مجال الرعاية الصحية والتغذية هدفها تحفيز الإبداع والابتكار، مما يؤهل الدارسين والمتدربين لمواكبة تطورات ميدان الرعاية الصحية والتغذية بكفاءة وفاعلية.</div>

   <div className='headingdiv'>:
    رؤية الأكاديمية</div>
   <div className='paragraphdiv'>نسعى في أن نكون الرواد عالميا في تقديم التثقيف الصحي والتغذوي، وتعزيز الوعي بأسباب المرض وكيفية الوقاية منها، بهدف بناء مجتمع أكثر صحة وسعادة.</div>
   <div className='headingdiv'>:
    رسالة الأكاديمية</div>
   <div className='paragraphdiv'>نؤمن بأهمية توفير المعلومات الصحيحة والموثوقة للمجتمع، وتقديم التدريب الصحي والتغذوي المناسب لجميع الفئات العمرية. نسعى جاهدين لتحفيز الأفراد على تغيير أساليب حياتهم نحو الأفضل، وتبني عادات صحية تدوم مدى الحياة.</div>
   <div className='headingdiv'>:
    قيم الأكاديمية
    </div>
   <div className='lisitemsdivs'>
    <ul>
        <li>تصميم حقائب تدريب متميزة ومتخصصة تغطي مجموعة
متنوعة من المواضيع المتعلقة بالتغذية الصحية واللياقة
البدنية.</li>
        <li>تصميم برامج تغذوية تناسب نمط الحياة من خلال
تطويرها المستمر تلبي احتياجات الفرد والمجتمع بشكل
أفضل</li>
        <li>تعزيز التواصل مع الجهات المكملة والداعمة لرؤيتنا من
خلال بناء شراكات مع المؤسسات الصحية والمجتمعية
التوفير خدمات متكاملة ومتعددة القطاعات للمجتمع</li>
        <li>. زيادة الوعي الصحي من خلال تقديم حملات توعية وورش
        عمل منتظمة للمجتمع</li>
        <li>توفير استشارات فردية لمساعدتهم في تحديد أهدافهم
        الصحية ووضع خطط عمل شخصية لتحقيقها.</li>
        <li>قياس التأثير والنتائج من خلال تقييم الأثر والنتائج للأنشطة
والبرامج المقدمة لضمان تحقيق الأهداف المحددة .
وتحسين العملية التدريبية بشكل مستمر</li>
        <li>تعزيز ثقافة التحسين المستمر داخل الأكاديمية الضمان
        تقديم خدمات عالية الجودة وتحقيق التميز في الأداء</li>
    </ul>
   </div>
</div>

<div className="ownerfooter">
    <Footer/>
</div>

    </div>
    
    
    </>
  )
}

export default Owner;