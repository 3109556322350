// src/Components/User/useSessionErrorHandler.js
import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';

export function useSessionErrorHandler() {
  const { setUsersession } = useContext(UserContext);

  const handleSessionError = (err) => {
    if (err.response && err.response.status === 401) {
      setUsersession(true); // Set session to false to indicate an error
    } else {
      // Handle other error cases
      console.error('Error:', err);
    }
  };

  return handleSessionError;
}
