import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { UserContext } from '../../../context/UserContext';
import bookimage from '../../../Asserts/Images/book.svg'
import cardlogo from '../../../Asserts/Images/cardlogo.png'

function Pinnedcard({ courses }) {
  const { setSuccessAction, setSuccess, user } = useContext(UserContext);
  const [popupIndex, setPopupIndex] = useState(null);
  const [deletePopup, setDeletePopup] = useState(false);
  const [deletedId, setDeletedId] = useState(null);
  const navigate = useNavigate();

  const showPopup = (e, index) => {
    e.stopPropagation();
    setPopupIndex(popupIndex === index ? null : index);
  };

  const goToUploadVideo = (e, id) => {
    e.stopPropagation();
    navigate(`/uploadbanner/${id}`);
  };

  const navigateSuccess = (e) => {
    e.stopPropagation();
    navigate("/success");
    setSuccessAction("/");
    setSuccess("Success message");
  };

  const showDelete = (e, id) => {
    e.stopPropagation();
    setDeletePopup(!deletePopup);
    setDeletedId(id);
  };

  const performDeleteAction = async () => {
    // Implement delete action here using `deletedId`
    // After deletion, you can update the UI accordingly
    setDeletePopup(false);
  };

  // Create an array that holds the required course details
  const mappedCourses = [{
    imageUrl: bookimage,
    description: "this is dummy",
    title: "this is title",
    // timeString: course.timeString,
    // _id: course._id
  }];

  return (
    <>
      {deletePopup && (
        <div className="deletemaindiv">
          <div className="deletecontainer">
          <div className="deletetitle">
                <div>سبب الحذف</div>
                <div className="CloseHadafPopup" onClick={()=>setDeletePopup(false)}>x</div>
              </div>
            <textarea placeholder="مختلف عن موضوع القسم"></textarea>
            <button onClick={performDeleteAction}>
              <Icon icon="ic:baseline-delete" width="1.2em" height="1.2em" /> حذف
            </button>
          </div>
        </div>
      )}

      {mappedCourses && mappedCourses.length > 0 ? (
        mappedCourses.map((course, index) => (
          <div key={index} className="videointhedivmain" onClick={() => navigate(`/videos/${course._id}`)}>
            <div className="videoimageleft">
              <img src={course.imageUrl} alt="Video Thumbnail" />
            </div>
            <div className="videonamedesright">
              <div className="videonameandicon">
                {user && user.role === "admin" && (
                  <>
                    <div>
                      <Icon
                        onClick={(e) => showPopup(e, index)}
                        icon="bi:three-dots-vertical"
                        width="1.2em"
                        height="1.2em"
                      />
                    </div>
                    {popupIndex === index && (
                      <div className="popupmain">
                        <div onClick={(e) => goToUploadVideo(e, course._id)}>تعديل</div>
                        <div onClick={navigateSuccess}>إخفاء</div>
                        <div onClick={(e) => showDelete(e, course._id)}>حذف</div>
                      </div>
                    )}
                  </>
                )}
                <div className='imagecardlogo'><img src={cardlogo} alt="" /></div>
              </div>
              <div className="timeofvideoup">{course.title}</div>
              <div className="descrationofvideo">{course.description}</div>
            </div>
          </div>
        ))
      ) : (
        <div>No courses available</div>
      )}
    </>
  );
}

export default Pinnedcard;
