import React from 'react';
import './interviewdetails.css';

function Datetime({ Meeting }) {
  // Convert and format the date and time
  const date = new Date(Meeting.Meeting_Time);

  // Format the date as "day/month/year"
  const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

  // Format the time as "hours:minutes"
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedTime = `${hours % 12 || 12}:${minutes < 10 ? '0' : ''}${minutes}`;

  // Determine AM or PM in Arabic
  const amPm = hours >= 12 ? 'مساء' : 'صباحا';

  // Get day name (e.g., Thursday in Arabic)


  return (
    <>
      <div className='interviewtexth2'>
        <h2>تفاصيل المقابلة</h2>
      </div>

      <div className='meetingdetailtext'>
        <span> : التاريخ</span>
        <p>{Meeting?.Meeting_Day}</p>
        <p>{formattedDate}</p>
      </div>

      <div className='meetingdetailtext'>
        <span> : الساعة</span>
        <p>{formattedTime}</p>
        <p>{amPm}</p>
      </div>

      <div className='meetingdetailtext'>
        <span> : المكان</span>
        <p>Zoom Online</p>
      </div>

      <div className='interviewtexth2'>
        <h2> الوصف</h2>
      </div>

      <div className="descraptiondivwhole">
        {Meeting.Meeting_description}
      </div>
    </>
  );
}

export default Datetime;
