import React, { useState, useRef, useContext } from 'react';
import './Uploadbanner.css';
import Navbar from '../../User/Navbar/Navbar';
import Footer from '../../User/Footer/Footer';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import axios from 'axios';
import Loading from '../../User/Loading/Loading';
import { useSessionErrorHandler } from '../../User/Managesession';

function Uploadbanner() {

  const handleSessionError = useSessionErrorHandler();

  const navigate = useNavigate();
  const {user,setLoading,loading,setSuccessAction,token} = useContext(UserContext)
  const [activeButton, setActiveButton] = useState('إعلان متحرك');
  const [isPinned, setIsPinned] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    link: '',
    description: '',
    image: null,
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const fileInputRef = useRef(null);

  const goback = () => {
    navigate(-1);
  };

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if(name==='description'){
      if(value.length > 999){
        alert('لقد تجاوزت الحد الأقصى للوصف وهو 1000 حرف')
        return
      }
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevData) => ({
        ...prevData,
        image: file,
      }));
    }
  };

  const handleCustomButtonClick = () => {
    fileInputRef.current.click();
  };

  const validateForm = () => {
    return (
      formData.title &&
      formData.link &&
      formData.description &&
      formData.image
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    if(activeButton==="إعلان مثبت"){
      setIsPinned(true)
    }
    else{
      setIsPinned(false)
    }
    if (validateForm()) {
      const formDataToSend = new FormData();
      formDataToSend.append('title', formData.title);
      formDataToSend.append('link', formData.link);
      formDataToSend.append('description', formData.description);
      formDataToSend.append('bannerPic', formData.image);
      formDataToSend.append('isPinned', isPinned);

      setLoading(true)
      await axios.post(`${process.env.REACT_APP_SERVER_URL}/banner/create`, formDataToSend,
        {headers : {authorization : `Bearer ${token}`}}
      ).then((response)=>{
        console.log(response.data)
        setLoading(false)
        // setSuccessAction('/')
        navigate('/success')
        // navigate(-1)
      }).catch((err)=>{
        console.log(err)
        handleSessionError(err);
        setLoading(false)
      })
  };
  }
  return (
    loading?<Loading/>:
    <>
      <div className="uploadbannermaindiv">
        <div className="uplaodbannernavbar">
          <Navbar />
        </div>

        <div className="bannercontaierdiv">
          <div className="toptitlediv">
            <div className="backicondiv" onClick={goback}>
              <Icon icon="ic:round-arrow-back-ios" width="1.2em" height="1.2em" />
            </div>
            <div className="titledivbaner">إضافة إعلان</div>
          </div>

          <div className="buttonsdiv">
            <button
              className={activeButton === 'إعلان متحرك' ? 'active-button' : ''}
              onClick={() => handleButtonClick('إعلان متحرك')}
            >
              إعلان متحرك
            </button>
            <button
              className={activeButton === 'إعلان مثبت' ? 'active-button' : ''}
              onClick={() => handleButtonClick('إعلان مثبت')}
            >
              إعلان مثبت
            </button>
          </div>

          <div className="uploadimagediv">
            {formData.image && (
              <img
                src={URL.createObjectURL(formData.image)}
                alt="Preview"
                className="image-preview"
              />
            )}
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleImageUpload}
              style={{ display: 'none' }}
            />
            <button
              onClick={handleCustomButtonClick}
              style={{backgroundColor: formSubmitted && !formData.image && 'red' }}
            >
              ارفع صورة الغلاف
            </button>
          </div>
          <div className="formdivmain">
            <label htmlFor="title">عنوان الإعلان</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              className={formSubmitted && !formData.title ? 'input-error' : ''}
            />

            <label htmlFor="link">رابط الإعلان</label>
            <input
              type="text"
              name="link"
              value={formData.link}
              onChange={handleInputChange}
              placeholder="بالرجاء ادخال رابط الصفحة الذي تريد ربطه بالإعلان "
              className={formSubmitted && !formData.link ? 'input-error' : ''}
            />

            <label htmlFor="description">الوصف</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              placeholder='الحد 1000 حرف'
              className={formSubmitted && !formData.description ? 'input-error' : ''}
            />
          </div>

          <div className="submitmaindiv">
            <button onClick={handleSubmit}>تم</button>
          </div>
        </div>

        <div className="bannerfooterdiv">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Uploadbanner;