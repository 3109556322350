import React, { useContext, useEffect, useState } from 'react';
import './Communication.css';
import Navbar from '../../User/Navbar/Navbar';
import Footer from '../../User/Footer/Footer';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { UserContext } from '../../../context/UserContext';
import Loading from '../../User/Loading/Loading';
import { useSessionErrorHandler } from '../../User/Managesession';

function Communication() {
    const navigate = useNavigate();

    const handleSessionError = useSessionErrorHandler();

    const [isEnabled, setIsEnabled] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [submitAttempt, setSubmitAttempt] = useState(false);
    const [whatsappDetails, setWhatsAppDetails] = useState({});
    const {token} = useContext(UserContext);
    const {success,setSuccess,setSuccessAction,setLoading,loading} = useContext(UserContext);

    const goback = () => {
        navigate('/');
    };

    const handleToggle = () => {
        setWhatsAppDetails({ ...whatsappDetails, feature_status: !whatsappDetails?.feature_status });

    };
    const handleSaveWhatsAppDetails = async () => {

    };

    const handleInputChange = (e) => {
        setPhoneNumber(e.target.value);
    };
    const getWhatsAppdetails = async()=>{
        try{
            setLoading(true)
            const response = await axios.patch(`${process.env.REACT_APP_SERVER_URL}/whatsapp/getdetails`,{},{headers : {authorization : `Bearer ${token}`}});
            console.log("whatsapp",response.data.response)
            setWhatsAppDetails(response.data.response[0])
            setPhoneNumber(response.data.response[0].whatsAppNumber)
            setLoading(false)
        }catch(err){
            console.log(err)
            handleSessionError(err);
            setLoading(false)
        }
    }
    useEffect(()=>{
        getWhatsAppdetails();
    },[])



        const handleSubmit = async () => {
            setSubmitAttempt(true);

            if (!phoneNumber) {
                return; // Stop submission if the phone number field is empty
            }



            try {
                setLoading(true)
                try {
                    if (whatsappDetails._id) {
                        // Update existing WhatsApp details
                        await axios.patch(`${process.env.REACT_APP_SERVER_URL}/whatsapp/updateDetails/${whatsappDetails._id}`, {feature_status:whatsappDetails?.feature_status,whatsAppNumber:phoneNumber}, {
                            headers: { "authorization": `Bearer ${token}` }
                        }).then((response)=>{
                            console.log("whatsapp details updated",response.data.response)
                            setPhoneNumber(response.data.response.whatsAppNumber)
                            setWhatsAppDetails(response.data.response)
                        })
                    } else {
                        // Create new WhatsApp details
                        await axios.post(`${process.env.REACT_APP_SERVER_URL}/whatsapp/createDetails`, whatsappDetails, {
                            headers: { "authorization": `Bearer ${token}` }
                        }).then((response)=>{
                            console.log("whatsapp details created",response.data.response)
                            setWhatsAppDetails(response.data.response)
                            setPhoneNumber(response.data.response.whatsAppNumber)

                        }
                        )
                    }

                } catch (err) {
                    console.error('Error saving WhatsApp details:', err);
                    handleSessionError(err);
                }



                setSuccess("تم تحديث الاعدادات بنجاح")
                setSuccessAction("/communation")
                setLoading(false)
                navigate("/success")

                // Handle success message or redirect here if needed
            } catch (error) {
                handleSessionError(error);
                setLoading(false)
                console.error('Error updating communication settings:', error);
                // Handle error message here if needed
            }
        };



    return (
        loading?<Loading/>:
        <>
            <div className="communationmin">
                <div className="communationnvar">
                    <Navbar />
                </div>

                <div className="communationcontainer">
                    <div className="navtopbar">
                        <div className="backiconsdiv" onClick={goback}>
                            <Icon icon="ic:round-arrow-back-ios" width="1.2em" height="1.2em" />
                        </div>

                        <div className="maintitlediv">
                            اعدادات التواصل
                        </div>
                    </div>

                    <div className={`"toggle-container" ${submitAttempt && phoneNumber && "inputerrorclass"}`}>
                        <label className="toggle-label" style={{ color: whatsappDetails?.feature_status && "#B571AC" }}>
                            تفعيل
                            <div className={`toggle-switch ${whatsappDetails?.feature_status ? 'on' : 'off'}`} onClick={handleToggle}>
                                <div className="toggle-knob"></div>
                            </div>
                        </label>
                    </div>

                    <div className="input-container">
                        <label>رقم الهاتف</label>
                        {/* <input
                            type="number"
                            placeholder="الرجاء إدخال رقم التواصل الخاص بك"
                            value={phoneNumber}
                            onChange={handleInputChange}
                            className={submitAttempt && !phoneNumber ? 'input-error' : ''}
                        /> */}
                         <PhoneInput
                                    id={'phone'}
                                    country={'sa'}
                                    value={phoneNumber}
                                    onChange={(phone) => setPhoneNumber(phone)}
                                    enableSearch={true}
                                    placeholder='بالرجاء ادخال رقم الجوال '
                                    containerClass={submitAttempt && phoneNumber === '' ? 'input-error' : ''}
                                />
                    </div>

                    <div className="submit-button-container">
                        <button onClick={handleSubmit}>تم</button>
                    </div>
                </div>

                <div className="communationfooter">
                    <Footer />
                </div>
            </div>
        </>
    );
}

export default Communication;
