import React, { useEffect, useState, useContext } from 'react';
import './Paymentdetail.css';
import InvoiceTemplate from './InvoiceTemplate';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import axios from 'axios';
import { UserContext } from '../../../context/UserContext';
import Loading from '../Loading/Loading';
import '../payment/Payment.css';
import { useSessionErrorHandler } from '../Managesession';

function Paymentdetail({ paymentid }) {
  const handleSessionError = useSessionErrorHandler();
  const { user, loading, setLoading, token } = useContext(UserContext);
  const navigate = useNavigate();
  const [paymentdata, setpaymentdata] = useState({});
  const { id } = useParams();

  const goback = () => {
    navigate('/');
  };


  const [copied, setCopied] = useState(false)
  const copyText = (data) => {
    navigator.clipboard.writeText(data).then(() => {
      setCopied(true); // Set copied to true if successful
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    }).catch(err => {
      console.error("Failed to copy text: ", err);
    });
  };

  const gettranscitionbyId = async () => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_SERVER_URL}/payments/${id}`, {
      headers: {
        authorization: `Bearer ${token}`
      }
    }).then((response) => {
      console.log('paymentdata', response.data.payment);
      setpaymentdata(response.data.payment);
      setLoading(false);
    }).catch((err) => {
      setLoading(false);
      handleSessionError(err);
      console.log(err);
    });
  };

  useEffect(() => {
    console.log(id);
    gettranscitionbyId();
  }, []);

  return (
    loading ? <Loading /> :
      <>
        <Navbar />
        <div className='payemntdetailsmaintop'>
          <div className="paymentleftsideandheading">
            <div className='leftarrow'>
              <Icon onClick={goback} className='leftarrowicon' icon="mdi:keyboard-arrow-left" />
            </div>
            <div className='paymenttextdiv'>
              <h1>{paymentdata.services_details}</h1>
              
              
              {/* {
                user?.role !== 'user' &&
                (
                  copied  ?
                  <div> copied </div> :
                  <div onClick={()=>copyText(paymentdata.transcition_id)}>copy</div>
                )
              }
               */}
            </div>
          </div>
          <div className='amount-heading'><h1> ريال </h1><h1>{' '}{paymentdata.transcition_amount}</h1></div>
          <div className='p-d-dashed-line'></div>

          <div className='p-d-row-wide'>
            <div>{`${paymentdata.transcition_by?.last_name} ${paymentdata.transcition_by?.first_name}`}</div>
            <div className='p-d-r-w-pinkie'>المشتري</div>
          </div>
          <div className='p-d-row-wide'>
            <div>{paymentdata.seller_information}</div>
            <div className='p-d-r-w-pinkie'>البائع</div>
          </div>
          <div className='p-d-row-wide'>
            <div>{paymentdata.services_details}</div>
            <div className='p-d-r-w-pinkie'>الخدمة</div>
          </div>

          <div className='p-d-dashed-line1'></div>

          <div className='p-d-row-wide'>
            <div>{paymentdata.transcition_amount}</div>
            <div className='p-d-r-w-pinkie'>المبلغ</div>
          </div>
          <div className='p-d-row-wide'>
            <div>{paymentdata.transcition_id}</div>
            <div className='p-d-r-w-pinkie'>الرقم المرجعي</div>
          </div>
          <div className='p-d-row-wide'>
            <div>{paymentdata.transcitionDate}</div>
            <div className='p-d-r-w-pinkie'>التاريخ</div>
          </div>
          <div className='p-d-row-wide'>
            <div>{paymentdata.transcitionTime}</div>
            <div className='p-d-r-w-pinkie'>الوقت</div>
          </div>
          {/* <div className='p-d-row-wide'>
            <div>{'Payment Method'}</div>
            <div className='p-d-r-w-pinkie'>وسيلة الدفع</div>
          </div> */}

          <div className='p-d-solid-line'></div>
          
          <div className="detailcontentmaindiv">
            {/* <div>
              <h2>معلومات البائع</h2>
            </div>

            <div className='pandspandivpaymentdetail'>
              <p><span>{paymentdata.seller_information}</span> : الاسم</p>
              <h3>معلومات المشتري</h3>
              <p><span>{`${paymentdata.transcition_by?.last_name} ${paymentdata.transcition_by?.first_name}`}</span> : الاسم</p>
              <p><span>{paymentdata.transcition_by?.phone_number}</span> : رقم الهاتف</p>
              <h4>تفاصيل الخدمة</h4>
            </div>
            <h2>{paymentdata.transcition_status} : حالة</h2>
            {paymentdata?.card_number&& <h2>Card:{paymentdata?.card_number}</h2>}

            <div className='showpaymentdiv'>
              <h5>{paymentdata.services_details}</h5>
              <h5>{paymentdata.transcition_amount} ريال</h5>
            </div>

            <h2> اجمالي المبلغ</h2>
            <h6>{paymentdata.transcition_amount} ريال</h6> */}

            {Object.keys(paymentdata).length > 0 && (
              <div className='btnpaymentdetaildiv'>
                <PDFDownloadLink
                  document={<InvoiceTemplate paymentData={paymentdata} />}
                  fileName={`Invoice_${paymentdata.transcition_id}.pdf`}
                >
                  {({ loading }) => (
                    <button className='download-btn'>
                      {loading ? 'Loading document...' : 'تحميل الفاتورة'}
                    </button>
                  )}
                </PDFDownloadLink>
              </div>
            )}
          </div>
        </div>
      </>
  );
}

export default Paymentdetail;