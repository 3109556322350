import React, { useContext, useEffect, useState } from 'react'
import ProfilePic from './../../../../../Asserts/Images/DummyProfile.png'
import { Icon } from '@iconify/react/dist/iconify.js'
import { ManagersContext } from '../../ManagersManagementContext/ManagersContext'
import Navbar from '../../../../User/Navbar/Navbar'
import Footer from '../../../../User/Footer/Footer'
import { useNavigate, useParams } from 'react-router-dom'
import { UserContext } from '../../../../../context/UserContext'
import { useSessionErrorHandler } from '../../../../User/Managesession'
import axios from 'axios'
import Loading from '../../../../User/Loading/Loading'
function ManagerActivity() {

 const [manageractivities,setManagerActivities] = useState([])
  const navigate = useNavigate()
  const [activityloading,setActivityLoading]  = useState(true)
  const {token,loading,setLoading,user} = useContext(UserContext)
  const handleSessionError =  useSessionErrorHandler()

  const { id } = useParams();

  function moveBack () {
    navigate('/allmanagers')
  }

  function Hazaf () {
    navigate('/allmanagers')
  }
  function moveForward (managerId,activityId){
    navigate(`/managerdetail/${activityId}`)
  }
  const [popup,setPopup] = useState(false)
  function managePopup () {
    if(popup){setPopup(false)}
    else{setPopup(true)}
  }
 const  getManagerActivities = async(adminId)=>{
    try{
      setActivityLoading(true)
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/admin/allactivities?adminId=${adminId}`,{headers:{
        authorization:`Bearer ${token}`
      }})
      console.log("manager activities",response)

      setManagerActivities(response.data.response)
      setActivityLoading(false)

 }catch(err){
   console.log(err)
   handleSessionError(err)
   setActivityLoading(false)
 }
}
  useEffect(()=>{
    if(user?.role==='super-admin'){
    getManagerActivities(id)
    }

  },[])
  return (
activityloading?<Loading/>:
    <>
    <div> <Navbar/> </div>
    <div className='manageractivity-main'>
      <div className='manageractivity-nav'>
        <div className='manageractivity-back' onClick={moveBack}>
        <Icon icon="weui:back-filled" />
        </div>
        <div className='manageractivity-nav-text'>
          <div className='managementactivity-nav-name'>{manageractivities[0]?.userId?.first_name}</div>
          <div>
          <Icon icon="weui:back-outlined" />
          </div>
          <div onClick={moveBack}>
          المديرون
          </div>
        </div>
      </div>
      <div className='manageractivity-body'>
        <div className='manageractivity-body-managerprofile'>
          <div className='manageractivity-body-profile'>
            <div className='manageractivity-body-profile-pic'>
              <img src={manageractivities[0]?.userId?.photo} alt="pichkur" />
            </div>
            <div className='manageractivity-body-profile-text'>
              <div>{manageractivities[0]?.userId?.first_name}</div>
            <div className='manageractivity-body-profile-date'>
              {new Date(manageractivities[0]?.userId?.created_at).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit'
              })} : تاريخ الانضمام
            </div>
            </div>

            {/* <div className='manageractivity-body-profile-dots' onClick={managePopup}>
            <Icon icon="pepicons-pencil:dots-y" />
            </div> */}
            {/* {popup? <div className='allmanager-popup'>
            <div className='allmanager-popup-text' onClick={Taalik}>تعليق</div>
            <div className='allmanager-popup-line'></div>
            <div className='allmanager-popup-text' onClick={Hazaf}>حذف</div>
        </div> : <></>} */}
          </div>
        </div>
        <div className='manageractivity-body-activity'><div>النشاط</div></div>
        <div className='manageractivity-body-activities'>
          <div className='manageractivity-body-activities-container'>
            {
              manageractivities?.length > 0 ? manageractivities.map((item) => {


                return (
                  <div className='manageractivity-body-activities-box' onClick={() => moveForward(item?.userId?._id,item._id)}>
                    <div className='manageractivity-body-activities-dateandtime'>{`${item.date} : ${item.day.substring(0,3)}`}</div>
                    <div className='manageractivity-body-activities-things'>
                      <div>{item.firstLoginTime} : اول ظهور</div>
                      <div>{item.totalUploadedVideos} : الفيديوهات المرفوعة</div>
                      <div>{item.totalModifications} : التعديلات</div>
                      <div>{item.lastLogoutTime} : اخر ظهور</div>
                    </div>
                  </div>
                );
              }) : null
            }

          </div>
        </div>
      </div>
    </div>
    <div>
      <Footer/>
    </div>
    </>
  )
}

export default ManagerActivity
