import React from 'react'
import './pagination.css'

function Pagination({increment, decrement, activePage, totalPages}) {
  return (
    <div className='sa-pagination-container'>
      <div className={activePage === 1 ? 'sa-pagination-left-inactive' : 'sa-pagination-left'}
      onClick={decrement} >
        {'<'}
      </div>
      <div className='sa-pagination-active'>
        {activePage}
        </div>
      <div className={activePage === totalPages ? 'sa-pagination-left-inactive' : 'sa-pagination-left' }
      onClick={increment}>
        {'>'}
        </div>
    </div>
  )
}

export default Pagination
