import React, { useContext, useEffect, useState } from 'react'
import dummyProfile from './../../../../../Asserts/Images/DummyProfile.png'
import { Icon } from '@iconify/react/dist/iconify.js'
import './userprofile.css'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { UserContext } from '../../../../../context/UserContext'
import moment from 'moment'
import Loading from '../../../../User/Loading/Loading'

function UserProfile() {
  const {userId} = useParams()
  const [userData,setUserData] = useState({})
  const {token,setLoading,loading} = useContext(UserContext)
  console.log(userData.gender)
  const getProfileData = async () => {
    try {
      setLoading(true)
      // Fetch user profile data
      await axios.get(`${process.env.REACT_APP_SERVER_URL}/user/userdetails/${userId}`, {
        headers: {
          'authorization': `Bearer ${token}`
        }
      }

      ).then((response) => {
        console.log(response)
        setUserData(response.data.data)
        setLoading(false)
      })
      .catch((error) => {
        setUserData({})
        setLoading(false)
        console.error('Error fetching user profile data:', error)
      })


    } catch (error) {
      setLoading(false)
      console.error('Error fetching user profile data:', error)
    }
  }

  useEffect(()=>{
    console.log('User Profile')
    getProfileData()

  },[])
  return (
    loading?"DummyLoading":
    <div className='sa-userprofile-container'>
      <div className='sa-up-p-data'>
        <div className='sa-up-pd-img'>
           {userData.photo? <img src={userData?.photo} alt='Profile Pic' />:<img src={dummyProfile} alt='Profile Pic' />}
        </div>
        <div className='sa-up-pd-data'>
            <div>{userData?.first_name+" "+userData?.last_name}</div>
            <div>{'30'}<Icon icon="majesticons:coins" /> :نقاط العافية</div>
        </div>
      </div>
      <div className='sa-up-details'>
        <div className='sa-up-d-heading'>
        المعلومات الشخصية
        </div>
        <div className='sa-up-d-details'>
            <div className='sa-up-d-sec1'>
                <div className='details-direction'>رقم الجوال</div>
                <div>{userData?.phone_number}</div>
            </div>
            <div className='sa-up-d-sec1'>
                <div className='details-direction'>البريد الالكتروني</div>
                <div>{userData?.email}</div>
            </div>
            <div className='sa-up-d-sec1'>
                <div className='details-direction'>تاريخ الميلاد</div>
                <div>{moment(userData?.Date_Of_Birth).format('DD/MM/YYYY')}</div>            </div>
            <div className='sa-up-d-sec2'>
                <div className='details-direction'>الجنس</div>
                <div>{userData?.gender}</div>
            </div>
        </div>
        <div className='sa-up-d-heading'>
        النشاط
        </div>
      </div>
    </div>
  )
}

export default UserProfile
