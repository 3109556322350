import React from 'react'
import './subscribedsection.css'

function Subscribedsection() {
  return (
    <div className='subscribe-container'>
      {/* map here */}
      <div className='subcribed-info'>
      التغذية
      </div>
      <div className='subcribed-info'>
      المناعة
      </div>
      <div className='subcribed-info'>
      المعدة
      </div>
    </div>
  )
}

export default Subscribedsection
