import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import '@fontsource/amiri'
import amiriFont from '@fontsource/amiri/files/amiri-arabic-400-italic.woff'
import urduFont from './../../../Asserts/Fonts/static/Cairo-SemiBold.ttf'
Font.register({
  family: 'Amiri',
  src: urduFont, // URL to the Amiri font
});


const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
    lineHeight: 1.5,
    flexDirection: 'column',
    fontFamily: 'Amiri',
    direction: 'rtl'
  },
  header: {
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 20,
    color: '#333',
    fontWeight: 'bold',
  },
  section: {
    marginBottom: 15,
    padding: 10,
    border: '1pt solid #e5e5e5',
    borderRadius: 5,
  },
  label: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
    color: '#333',
  },
  value: {
    fontSize: 12,
    marginBottom: 2,
    color: '#555',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  totalAmount: {
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 20,
    color: '#222',
  },
  textColor: {
    color: '#5D5D5DC7',
    textAlign: 'right',
    marginTop: 5
  },
  statusColor:{
    color:'#5D5D5DC7',
    textAlign: 'right',
  }
  ,
  line:{
    width:'100%',
    borderBottomWidth: 1,
    borderBottomColor: 'gray',
    marginVertical: 5,
  },
  line1:{
    width:'100%',
    borderBottomWidth: 1,
    borderBottomColor: 'gray',
    marginVertical: 5,
    borderLeftStyle: 'dashed'
  }
  ,
  pinkie:{
    color: '#707070'
  },
  centerText: {
    textAlign: 'center',
    fontSize: 14,
    marginVertical: 10,
    fontWeight: 'bold',
    color: '#333',
    direction:'rtl'
  },
  textRight: {
    textAlign: 'right',
    color: '#333',
  },
});

const InvoiceTemplate = ({ paymentData }) => (
  <Document>
    <Page style={styles.page} size={{width: 400, height:500}}>
      {/* Invoice Header */}
      <Text style={styles.header}>{paymentData.services_details}</Text>
      <Text style={styles.header}> {'ریال'}  {paymentData.transcition_amount} </Text>

      {/* Seller Information */}

      <View style={styles.line1} />

      <View style={styles.row}>
          <View>
        <Text>{paymentData.payer_name} </Text>
        </View>
        <View>
          <Text style={styles.pinkie}>المشتري</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View>
        <Text>{paymentData.seller_information}</Text>
        </View>
        <View>
          <Text style={styles.pinkie}>البائع</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View>
        <Text>{paymentData.services_details}</Text>
        </View>
        <View>
          <Text style={styles.pinkie}>الخدمة</Text>
          </View>
        </View>

        <View style={styles.line1} />

        <View style={styles.row}>
          <View>
        <Text>ریال {paymentData.transcition_amount}</Text>
        </View>
        <View>
          <Text style={styles.pinkie}>المبلغ</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View>
        <Text>{paymentData.transcition_id}</Text>
        </View>
        <View>
          <Text style={styles.pinkie}>الرقم المرجعي</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View>
        <Text>{paymentData.transcitionDate}</Text>
        </View>
        <View>
          <Text style={styles.pinkie}>التاريخ</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View>
        <Text>{paymentData.transcitionTime}</Text>
        </View>
        <View>
          <Text style={styles.pinkie}>الوقت</Text>
          </View>
        </View>
        {/* <View style={styles.row}>
          <View>
        <Text>{paymentData.services_details}</Text>
        </View>
        <View>
          <Text style={styles.pinkie}>وسيلة الدفع</Text>
          </View>
        </View> */}


      {/* <View style={styles.textRight}>
        <Text style={styles.textColor}>معلومات البائع</Text>
        <View style={styles.line} />
        <Text>{paymentData.seller_information} : الاسم</Text>
      </View>

      {/* Buyer Information */}
      {/* <View style={styles.textRight}>
        <Text style={styles.textColor}>معلومات المشتري</Text>
        <View style={styles.line} />
        <Text>{paymentData.payer_name} : الاسم</Text>
        <Text>{paymentData.phone_number} : رقم الهاتف</Text> */}
      {/* </View> */}

      {/* Service Details */}
      {/* <View style={styles.textRight}>
        <Text style={styles.textColor}>تفاصيل الخدمة</Text>
        <View style={styles.line} />
        <Text>{paymentData.transcition_status} : حالة</Text> */}
        {/* <View style={styles.line} />
        <View style={styles.row}>
          <View>
        <Text>ریال {paymentData.transcition_amount}</Text>
        </View>
        <View>
          <Text>{paymentData.services_details}</Text>
          </View>
        </View>
       
      </View> */}

      {/* Total Amount */}
      {/* <View style={styles.line} />
      <Text style={styles.textColor}>اجمالي المبلغ</Text>
      <View style={styles.line} />
      <Text style={styles.centerText}>ریال {paymentData.transcition_amount}</Text> */} */}
    </Page>
  </Document>
);

export default InvoiceTemplate;
