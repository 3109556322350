import React, { useEffect,useState } from 'react'
import './Bloodimageanlysis.css'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import images from '../../../Asserts/Images/test.png'
import { useContext } from 'react'
import { UserContext } from '../../../context/UserContext'
import axios from 'axios'
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../Loading/Loading'
import Chatbox from './Chatbox'
import { useSessionErrorHandler } from '../Managesession'
import Slider from "react-slick";


export default function Bloodimageanlysis({goback,setAnadetails}) {
    const handleSessionError = useSessionErrorHandler();
    const {isverify,loading,setLoading,token} = useContext(UserContext);
    const [RequestData,setRequestData] = useState({})
    const {requestId} = useParams()
    const [isFullScreen, setIsFullScreen] = useState(null);
    const [isFullScreenPatient,setIsFullScreenPatient] = useState(null)
  function patientPics (index) {
    if(isFullScreenPatient === index)
      {
        setIsFullScreenPatient(null)
      }
      else{
      setIsFullScreenPatient(index);
      }
  }
  const toggleFullScreen = (index) => {
    if(isFullScreen === index)
    {
      setIsFullScreen(null)
    }
    else{
    setIsFullScreen(index);
    }
  };
function None () {
  setIsFullScreen(null)
  setIsFullScreenPatient(null)
}
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 2000,
        initialSlide: isFullScreen
      };

      const downloadFileFromS3 = async (fileKey, index) => {
        try {
          // Fetch the signed URL from the backend
          const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/s3/downloadpdf?fileKey=${encodeURIComponent(fileKey)}`, {
            headers: {
              authorization: `Bearer ${token}`, // Replace with your auth token logic
            },
          });

          if (!response.ok) throw new Error('Failed to fetch signed URL');

          const { downloadUrl } = await response.json();

          // Fetch the file from the download URL as a blob
          const fileResponse = await fetch(downloadUrl);
          if (!fileResponse.ok) throw new Error('Failed to download the file');

          const blob = await fileResponse.blob();
          const url = window.URL.createObjectURL(blob);

          // Create an anchor element and trigger the download
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `document_${index + 1}`); // Customize the filename
          document.body.appendChild(link);
          link.click(); // Trigger the download
          document.body.removeChild(link); // Clean up the DOM

          // Revoke the object URL to release memory
          window.URL.revokeObjectURL(url);
        } catch (error) {
          console.error('Error downloading file:', error);
        }
      };

      const downloadReplayedDocuments = async () => {
        if (RequestData.replayed_documents && RequestData.replayed_documents.length > 0) {
          for (const [index, fileUrl] of RequestData.replayed_documents.entries()) {
            // Extract the S3 file key from the file URL
            const fileKey = fileUrl.split('.com/')[1]; // Assuming the URL is in the format https://bucket-name.s3.region.amazonaws.com/folderName/filename

            // Call the function to handle each download
            await downloadFileFromS3(fileKey, index);
          }
        } else {
          alert('No files to download.');
        }
      };


const navigate = useNavigate();
const getRequestById = async(id)=>{
    console.log('idin',id)
    setLoading(true)
    await axios.get(`${process.env.REACT_APP_SERVER_URL}/patientrequests/${id}`,{
        headers:{
            authorization: `Bearer ${token}`,
        }
    }).then((response)=>{
        console.log(response.data.patientRequest)
        setRequestData(response.data.patientRequest)

        setLoading(false)
    }
    ).catch((error)=>{
        console.log('error',error)
        handleSessionError(error)
        setLoading(false)
    })

}
useEffect(()=>{
    getRequestById(requestId)
},[])


  return (
    loading?<Loading/>:
    <>

    <div className='bloodanalysismain'>
    <Navbar/>
<div className="bloodhaead">
                    <div className='leftarrow'>
                        <Icon onClick={()=>navigate(-1)}  className='leftarrowicon' icon="mdi:keyboard-arrow-left" />
                    </div>
                    <div className='paymenttextdiv'>
                        <div> {RequestData.request_title}</div>
                    </div>
                </div>
        <div className="bloodanalysiscontaier">

      <div className='attrachfiles'>الملفات المرفقة</div>
      {
          isFullScreenPatient !== null ? <div className="full-screen-image" onClick={()=>None()} ><Icon icon="ic:outline-close" /></div>  : <></>
        }
      {
          isFullScreen !== null ?
          <>
          <div className="images-slider">
      <Slider {...settings} className="images-slider1" >
        {RequestData.replayed_documents.map((image, index) => (
          <div  className="image-container">
            <img src={image} alt='ImageLoading'
             />

          </div>
        ))}
      </Slider>

    </div>

     </> : <></>
        }
      {
          isFullScreen !== null ? <div className="full-screen-image" onClick={()=>None()} ><Icon icon="ic:outline-close" /></div>  : <></>
        }
      {
          isFullScreenPatient !== null ?
          <>
          <div className="images-slider">
      <Slider {...settings} className="images-slider1" >
        {RequestData.request_documents.map((image, index) => (
          <div className="image-container">
            <img src={image} alt='ImageLoading'
             />

          </div>
        ))}
        <img src={images} alt='no pic'/>
      </Slider>

    </div>

     </> : <></>
        }
      <div className='imagesshowdiv'>
      {
    RequestData.request_documents && RequestData.request_documents
    .map((file, index) => {
        return <img key={index} src={file} alt={`Document ${index + 1}`}
        onClick={()=>patientPics(index)} />
    })
}
      </div>
      <div className='descraptiondivtit'>الوصف</div>
      <div className='descraptiondiv'>{RequestData.request_description
}</div>

<div className="doublelinewait">
    {RequestData.request_status === "مغلقة"
      ? "مغلقة"
      : RequestData.request_status === "قيد المعالجة"
        ? "قيد المعالجة"
        : "في انتظار مراجعة الملفات والتحاليل"
    }
</div>

{
    (RequestData.request_status ==="قيد المعالجة"||RequestData.request_status==="مغلقة")&& <>


<div className='attrachfiles'>التقارير والنظام الغذائي</div>
      <div className='imagesshowdiv'>
{RequestData.replayed_documents&&RequestData.replayed_documents.length>=0?RequestData.replayed_documents.map((file,index)=>{ return( <img src={file} alt={`Documenst ${index +1}`}
onClick={()=>toggleFullScreen(index)}/>)}):""}
      </div>

<div className="buttondivrequestpage">
    <button onClick={downloadReplayedDocuments}>تحميل المفات</button>
</div>

{<Chatbox status={RequestData.request_status}/>}

</>
}
        </div>
    </div>
    </>
  )
}
