import React, { useContext, useEffect, useState } from 'react'
import { Icon } from '@iconify/react/dist/iconify.js'
import { ManagersContext } from '../../ManagersManagementContext/ManagersContext'
import ProfilePic from './../../../../../Asserts/Images/DummyProfile.png'
import ManagerVideos from './ManagerDetailsComponents/ManagerVideos'
import ManagerLatestModifications from './ManagerDetailsComponents/ManagerLatestModifications'
import Navbar from '../../../../User/Navbar/Navbar'
import Footer from '../../../../User/Footer/Footer'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { useSessionErrorHandler } from '../../../../User/Managesession'
import { UserContext } from '../../../../../context/UserContext'
import Loading from '../../../../User/Loading/Loading'

function ManagerDetail() {
  const handleSessionError = useSessionErrorHandler()
  const {token,loading,user} = useContext(UserContext)
  const [activityLoading,setActivityLoading] = useState(true)

  const navigate = useNavigate();

  const { idactivity } = useParams();
  const [activityitem,setActivityitem] = useState([])

  const {managerSlide,setManagerSlide,activity,setActivity,manager} = useContext(ManagersContext)
  function moveBack () {
    setManagerSlide(managerSlide-1)
    navigate(-1)
  }
  function moveToFirst () {
    navigate('/allmanagers')
  }
  function Taalik () {
    navigate('/allmanagers')
  }
  function Hazaf () {
    navigate('/allmanagers')
  }
   const getActivities  = async(activityId)=>{
    try{
      setActivityLoading(true)
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/admin/activity/${activityId}`,{headers:{
        authorization:`Bearer ${token}`
      }})
      console.log("activity",response.data)
      setActivityitem(response.data.response)
setActivityLoading(false)    }catch(err){
      console.log(err)
      setActivityLoading(false)
      handleSessionError(err)
    }
  }

  useEffect(()=>{
    if(user?.role==='super-admin')
    getActivities(idactivity)
  },[idactivity])
  const [popup,setPopup] = useState(false)
  function managePopup () {
    if(popup){setPopup(false)}
    else{setPopup(true)}
  }
  function settingActivity (arg) {
    setActivity(arg)
  }
  return (

      activityLoading ? <Loading/> :

  <>


  <div>
            <Navbar/>
        </div>
    <div className='managerdetail-main'>
      <div className='manageractivity-nav'>
        <div className='manageractivity-back' onClick={moveBack}>
        <Icon icon="weui:back-filled" />
        </div>
        <div className='manageractivity-nav-text'>
          <div className='managementactivity-nav-name'>{activityitem?.userId?.first_name}</div>
          <div>
          <Icon icon="weui:back-outlined" />
          </div>
          <div onClick={moveToFirst}>
          المديرون
          </div>
        </div>
      </div>
      <div className='managerdetails-body'>
        <div className='managerdetails-body-profile'>
          <div className='managerdetails-body-profile-box'>
            <div className='managerdetails-body-profile-left'>
              <div>{activityitem.date} : {activityitem.day}</div>
              <div>{activityitem.firstLoginTime} : أول ظهور</div>
              <div>{activityitem.lastLogoutTime} : اخر ظهور</div>
            </div>
            <div style={{height:'100%',borderLeft:'1px solid #b571acab'}}></div>
            <div className='managerdetails-body-profile-right'>
              <div>
                <img src={activityitem?.userId?.photo} alt="" />
              </div>
              <div className='managerdetails-body-profile-name-date'>
              <div style={{fontSize:'13px'}}>{activityitem?.userId?.first_name}</div>
              <div style={{fontSize:'10px'}}> {new Date(activityitem?.userId?.created_at).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit'
              })}: تاريخ الانضمام</div>
              </div>
            </div>

          </div>
        </div>
        <div className='managerdetails-body-buttons'>
          <div className='managerdetails-body-buttons-container'>
            <div className={activity === 'latest-modifications' ? 'managerdetails-body-button-mod-active' : 'managerdetails-body-button-mod' }><button onClick={()=>settingActivity('latest-modifications')}>آخر التعديلات</button></div>
            <div className={activity === 'videos' ? 'managerdetails-body-button-mod-active' : 'managerdetails-body-button-mod' }><button onClick={()=>settingActivity('videos')}>الفيديوهات</button></div>
          </div>
        </div>
        <div>
          { activity === 'videos' ? <ManagerVideos activityVideos={activityitem?.uploadedVideos} /> : <></> }
          { activity === 'latest-modifications' ? <ManagerLatestModifications activityVideos={activityitem?.updatedVideos} /> : <></> }
        </div>
      </div>
    </div>
    <div>
            <Footer/>
        </div>
    </>
  )
}

export default ManagerDetail
