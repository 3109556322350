import React from "react";
import samplePic from "./../../../../../Asserts/Images/test.png";
import sampleProfile from './../../../../../Asserts/Images/DummyProfile.png'

function VideosEdited({videosEdited}) {
  console.log("props", videosEdited);

  // Ensure videosEdited is an array
  return (
    <div className="videoedited-profile-wholedata">
      {Array.isArray(videosEdited) && videosEdited.length > 0 ? (
        videosEdited.map((item, index) => (
          <div key={index} className="videoedited-profile-container">
            <div className="videoedited-profile-box">
              <div className="videoedited-profile-box-editings">
                <div>{item.totalModifications} : تم تعديل</div>
                <div>{item.date} : تاريخ التعديل</div>
              </div>
              <div className="videoedited-profile-box-line"></div>
              <div className="videoedited-profile-box-profile">
                <div>{item?.userId?.first_name}</div>
                <div className="videoedited-profile-profile-img">
                  <img src={item?.userId?.photo || sampleProfile} alt="Profile Picture" />
                </div>
              </div>
            </div>
            {item.updatedVideos?.length>0? item?.updatedVideos?.map((video, idx) => (

            <div className="managerdetails-video-box">
              <div className="managerdetails-video-box-left">
                <img src={samplePic} alt="Doctor" />
              </div>
                <div key={idx} className="managerdetails-video-box-right">
                  <div className="managerdetails-right-titletime">
                    <div className="managerdetails-right-heading">{video.title}</div>
                  </div>
                  <div className="managerdetails-right-description">{video.description}</div>
                </div>

            </div>
            )):'No activities on this day...'}
          </div>
        ))
      ) : (
        <div>No videos edited yet.</div>
      )}
    </div>
  );
}


export default VideosEdited;
