import React, { useContext, useEffect, useState } from 'react'
import './useranalytics.css'
import { UserContext } from '../../../../../context/UserContext'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
function UserAnalytics() {
  const {token} = useContext(UserContext)
  const {userId} = useParams()
  const [userLabWorks,setUserLabWorks] = useState([])
const navigate = useNavigate()
  const getUserLabWorks = async() =>{
    try{
      // Fetch user lab works
      await axios.get(`${process.env.REACT_APP_SERVER_URL}/usermanagement/getAllLabWorks?userID=${userId}`, {
        headers: {
          'authorization': `Bearer ${token}`
        }
      }
      ).then((response) => {
        console.log("All Lab Works",response)
        setUserLabWorks(response.data.data)
      })
      .catch((error) => {
        setUserLabWorks({})
        console.error('Error fetching user profile data:', error)
      })
    } catch (error) {
      console.error('Error fetching user profile data:', error)
    }
  }
  useEffect(()=>{
    getUserLabWorks()


  },[])
  return (
    <div className='ui-interviews-container'>
        {/* for map */}
      {userLabWorks?.length > 0 ? (
        userLabWorks.map((labWork, index) => (
          <div key={index} className='ui-interviews' onClick={()=>navigate(`/collobies/${labWork?._id}`)} >
            <div className='ui-i-info'>
              <div className='ui-i-i-date'>{labWork.requestDay +" "+labWork.requestDate+" "+labWork.requestTime}</div>
              <div className='ui-i-i-title'>{labWork.request_title}</div>
              <div className='ui-a-description'>{labWork.request_description}</div>
            </div>
            <div className='ui-a-down'>
              <span className='ui-i-i-bold'>{labWork.request_status}</span>  <span className='ui-a-reply'></span>
            </div>
          </div>
        ))
      ) : (
        <div>No labworks</div>
      )}
    </div>
  )
}

export default UserAnalytics
