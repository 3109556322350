import React from 'react'
import book from '../../../../../Asserts/Images/book.svg'

function UserPurchases() {
  return (
    <div className='ui-interviews-container'>
        {/* map here */}
      <div className="mainbookdiv">
              <img src={book} alt="" className='book-img' />
              <div className="paddingleftbotm">
                <h2>{'book.bookTitle'}</h2>
                
                <p>من تأليف <span>{'book.author'}</span></p>
                <p>{'Description'}</p>
                <h5>{'Price'} SR</h5>
              </div>
            </div>
    </div>
  )
}

export default UserPurchases
