import React, { useState } from 'react'
import Navbar from '../../User/Navbar/Navbar'
import Footer from '../../User/Footer/Footer'
import './addingadmin.css'
import { Icon } from '@iconify/react/dist/iconify.js'
import axios from 'axios'
import { useContext } from 'react'
import { UserContext } from '../../../context/UserContext'
import { useNavigate } from 'react-router-dom'
import { useSessionErrorHandler } from '../../User/Managesession'
import PhoneInput from 'react-phone-input-2'

function AddingAdmin() {
  const {token,setSuccessAction,setSuccess}= useContext(UserContext)
  const navigate = useNavigate()
  const handleSessionError = useSessionErrorHandler()

    const [nmbr,setNmbr] = useState('')
    const addAdmin = async() => {
       await axios.patch(`${process.env.REACT_APP_SERVER_URL}/admin/updateUserToAdmin?phone_number=${nmbr}`,{nmbr: nmbr},{
        headers: {
          authorization: `Bearer ${token}`,
        },

       }).then((res) => {
          setSuccessAction('/addingmanager')
          setSuccess('تم اضافة المدير بنجاح')
          navigate('/success')
            console.log(res)
        }).catch((err)=>{
          if(err.response.status === 404){

            window.alert('الرقم غير موجود')

          }
            console.log(err)
           handleSessionError(err)
        })
    }
  return (
    <div>
      <div><Navbar/></div>
      <div className='addingadmin-body'>
        <div className='addingadmin-body-heading'>إضافة مدير</div>
        <div className='addingadmin-body-number'>
            <label>رقم جوال المدير</label>
            <div className='inputphonedivlogin'>
            <PhoneInput
                                    country={'sa'}
                                    value={nmbr}
                                    onChange={(e)=>setNmbr(e)}
                                    enableSearch={true}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          addAdmin();
                                        }}}
                                    placeholder='الرجاء ادخال رقم جوال المدير'
                                />
                                </div>
            {/* <input type='text' placeholder='الرجاء ادخال رقم جوال المدير' onChange={(e)=>{setNmbr(e.target.value)}} value={nmbr} /> */}
        </div>
        {/* <div className='addingadmin-body-number1'>
            <label>المسمى الوظيفي</label>
            <div className='inputphonedivlogin'>
            <input type='text' placeholder='الرجاء ادخال المسمى الوظيفي' />
                                </div>
            
        </div> */}
        {/* <div className='m-s-bdy'>
               <div className='m-s-b-head'>الاختصاص</div>
               <div className='m-s-b-body'>
                <div className='m-s-b-b-row'>
                    <div>الفيديوهات والاعلانات الدعائية</div>
                    <div><input type='checkbox' /> </div>
                </div>
                <div className='m-s-b-b-row'>
                    <div>استلام التحاليل والرد عليها</div>
                    <div><input type='checkbox' /> </div>
                </div>
                <div className='m-s-b-b-row'>
                    <div>تنظيم المقابلات مع الدكتور</div>
                    <div><input type='checkbox' /> </div>
                </div>
               </div>
            </div> */}
        <div className='addingadmin-body-button'>
            <button onClick={addAdmin} >إضافة</button>
        </div>
      </div>
      <><div className="whatappicon" >
                <Icon icon="ic:round-whatsapp" width="1.2em" height="1.2em" />
            </div></>
      <div><Footer/></div>
    </div>
  )
}

export default AddingAdmin
