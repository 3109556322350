import React, { useEffect, useState } from "react";
import "./Mymeeting.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import Interviewdetail from "../InterviewDetail/Interviewdetail";
import { useContext } from "react";
import { UserContext } from "../../../context/UserContext";
import axios from "axios";
import norequest from '../../../Asserts/Images/norequest.png'
import { useSessionErrorHandler } from '../Managesession';
import BranchLoading from "../branchLoading/BranchLoading";



export default function Mymeeting() {
  const handleSessionError = useSessionErrorHandler();
  const navigate = useNavigate();
  const {
    user,
    usermeetings,
    setUsermeetings,
    setIspay,
    setIsapprovemet,
    token,
  } = useContext(UserContext);
  const [meetings, setMeetings] = useState([]);
  const [loading,setLoading] = useState(false)
  // const gotheinterviewdetailscreen = (id) => {
  //   // navigate to the interview detail screen with the id
  //   navigate(`/Interviewdetail/${id}`);
  // }
  const [meetingdetail, setMeetingdetail] = useState("meetings");
  const [activeStatus, setActiveStatus] = useState("");

  const gotheinterviewdetailscreen = (id) => {
    console.log(id);
    //  setMeetingdetail("meetingdetails")
    navigate(`/Interviewdetail/${id}`);
  };
  const getUserMeetings = async (status) => {
    console.log("UserMeeting", status);
    setLoading(true)
    try {
      console.log("try");
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/meeting/getmeetingsBy/${user._id}/${status}`,{
            headers: {
              authorization: `Bearer ${token}`,
          }
          }
        )
        .then((response) => {
          console.log("res");
          console.log(response.data);
          setMeetings(response.data.data);
          return;
        })
        .catch((err) => {
          setMeetings([]);
          console.log(err);
          handleSessionError(err);
          return;
        });
    } catch (error) {
      setMeetings([]);

      console.log(error);
    }
    setLoading(false)
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() is zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  useEffect(() => {
    getUserMeetings(usermeetings);
  }, [usermeetings]);
  const goback = () => {
    // setMeetingdetail("meetings")
    navigate(-1);
  };

  const tabStyles = {
    "اللقاءات المعلقة": {
      active: { backgroundColor: '#B571AC', color: '#FFFFFF' },
      inactive: { backgroundColor: '#FFFFFF', color: '#B571AC' }
    },
    "لقاءات غير مدفوعة": {
      active: { backgroundColor: '#B571AC', color: '#FFFFFF' },
      inactive: { backgroundColor: '#FFFFFF', color: '#B571AC' }
    },
    "اللقاءات المنتهية": {
      active: { backgroundColor: '#B571AC', color: '#FFFFFF' },
      inactive: { backgroundColor: '#FFFFFF', color: '#B571AC' }
    },
    "اللقاءات المرفوضة": {
      active: { backgroundColor: '#B571AC', color: '#FFFFFF' },
      inactive: { backgroundColor: '#FFFFFF', color: '#B571AC' }
    },
    "اللقاءات المحجوزة": {
      active: { backgroundColor: '#B571AC', color: '#FFFFFF' },
      inactive: { backgroundColor: '#FFFFFF', color: '#B571AC' }
    },
  };

  return (
    <div>
      <Navbar />

      {/* {
        meetingdetail === "meetings" && <> */}

      <div className="mymeetingmaindiv">
        <div className="meetingtextmain">
          <h2>لقاءاتي</h2>
        </div>

        <div className="buttonsmaindiv">
        <div
            style={{
              backgroundColor:
                usermeetings === "اللقاءات المحجوزة"
                  ? tabStyles["اللقاءات المحجوزة"].active.backgroundColor
                  : tabStyles["اللقاءات المحجوزة"].inactive.backgroundColor,
              color:
                usermeetings === "اللقاءات المحجوزة"
                  ? tabStyles["اللقاءات المحجوزة"].active.color
                  : tabStyles["اللقاءات المحجوزة"].inactive.color,
            }}
            onClick={() => {
              setUsermeetings("اللقاءات المحجوزة");
            }}>اللقاءات المحجوزة
          </div>

          <div
            style={{
              backgroundColor:
                usermeetings === "اللقاءات المعلقة"
                  ? tabStyles["اللقاءات المعلقة"].active.backgroundColor
                  : tabStyles["اللقاءات المعلقة"].inactive.backgroundColor,
              color:
                usermeetings === "اللقاءات المعلقة"
                  ? tabStyles["اللقاءات المعلقة"].active.color
                  : tabStyles["اللقاءات المعلقة"].inactive.color,
            }}
            onClick={() => {
              setUsermeetings("اللقاءات المعلقة");
            }}
          >
            اللقاءات المعلقة
          </div>

          <div
            style={{
              backgroundColor:
                usermeetings === "لقاءات غير مدفوعة"
                  ? tabStyles["لقاءات غير مدفوعة"].active.backgroundColor
                  : tabStyles["لقاءات غير مدفوعة"].inactive.backgroundColor,
              color:
                usermeetings === "لقاءات غير مدفوعة"
                  ? tabStyles["لقاءات غير مدفوعة"].active.color
                  : tabStyles["لقاءات غير مدفوعة"].inactive.color,
            }}
            onClick={() => {
              setUsermeetings("لقاءات غير مدفوعة");
              setIsapprovemet(true);
            }}
          >
           لقاءات غير مدفوعة

          </div>

          <div
            style={{
              backgroundColor:
                usermeetings === "اللقاءات المنتهية"
                  ? tabStyles["اللقاءات المنتهية"].active.backgroundColor
                  : tabStyles["اللقاءات المنتهية"].inactive.backgroundColor,
              color:
                usermeetings === "اللقاءات المنتهية"
                  ? tabStyles["اللقاءات المنتهية"].active.color
                  : tabStyles["اللقاءات المنتهية"].inactive.color,
            }}
            onClick={() => {
              setUsermeetings("اللقاءات المنتهية");
              setIspay(true);
              setIsapprovemet(true);
            }}
          >
            جاهزة للحضور
          </div>

          <div
            style={{
              backgroundColor:
                usermeetings === "اللقاءات المرفوضة"
                  ? tabStyles["اللقاءات المرفوضة"].active.backgroundColor
                  : tabStyles["اللقاءات المرفوضة"].inactive.backgroundColor,
              color:
                usermeetings === "اللقاءات المرفوضة"
                  ? tabStyles["اللقاءات المرفوضة"].active.color
                  : tabStyles["اللقاءات المرفوضة"].inactive.color,
            }}
            onClick={() => {
              setUsermeetings("اللقاءات المرفوضة");
            }}
          >
            اللقاءات المرفوضة
          </div>


        </div>

        { loading ? <BranchLoading/>:meetings && meetings.length > 0
          ? 
           <>{
          meetings.map((meeting, index) => {
              const date = new Date(meeting.Meeting_Time);
            console.log("date",meeting.Meeting_Time,"time",meeting.Meeting_Time)
              // Format the date as "day/month/year"
              const formattedDate = `${date.getDate()}/${
                date.getMonth() + 1
              }/${date.getFullYear()}`;

              // Format the time as "hours:minutes"
              const hours = date.getHours();
              const minutes = date.getMinutes();
              const formattedTime = `${hours}:${
                minutes < 10 ? "0" : ""
              }${minutes}`;

              // Determine AM or PM in Arabic
              const amPm = hours >= 12 ? "مساء" : "صباحا";

              return (
                <div
                  onClick={() => gotheinterviewdetailscreen(meeting._id)}
                  key={index}
                  className="maindivofapproval"
                >
                  <div className="dateanddaytext">
                    <p>{formattedDate}</p> {/* Display the formatted date */}
                  </div>
                  <div className="dronlinetext">
                    <h1>{meeting.Meeting_description}</h1>
                  </div>
                  <div className="showmeetingdetailtext">
                    <span>: التاريخ</span>
                    <p>{formattedDate}</p> {/* Display the formatted date */}
                  </div>
                  <div className="showmeetingdetailtext">
                    <span>: الساعة</span>
                    <p>{formattedTime}</p> {/* Display the formatted time */}
                    <p>{amPm}</p> {/* Display AM/PM in Arabic */}
                  </div>
                  <div className="line"></div>
                  <div className="approvaltext">
                    {meeting.Meeting_category === "اللقاءات المعلقة" && (
                      <p style={{ color: "#F1DA37" }}>
                        في انتظار موافقة الادمن
                      </p>
                    )}
                    {meeting.Meeting_category === "لقاءات غير مدفوعة" && (
                      <p style={{ color: "#9747FF" }}>
                        تمت الموافقة بالرجاء الدفع لتأكيد الحضور
                      </p>
                    )}
                    {meeting.Meeting_category === "اللقاءات المنتهية" && (
                      <p style={{ color: "#48B865" }}>
                        تمت الموافقة و تأكيد الحضور
                      </p>
                    )}
                    {meeting.Meeting_category === "اللقاءات المرفوضة" && (
                      <p style={{ color: "#993333" }}>الدكتور غير متاح</p>
                    )}
                  </div>
                </div>
              );
            })}</>
          : <>

          <div className="norequestmaindiv">
          <img src={norequest} alt="" />
          لم تقم بحجز أي مواعيد
          </div>

          </>}
      </div>

      {/* </>
      } */}

      {/*
      {
        meetingdetail === "meetingdetails" &&
        <>

        <Interviewdetail goback={goback} />

        </>
      } */}
      <Footer />
    </div>
  );
}
