import React, { useContext } from 'react'
import { Icon } from '@iconify/react/dist/iconify.js';
import { UserContext } from '../../../context/UserContext';
import Navbar from '../Navbar/Navbar';
import SimpleNavbar from '../SimpleNavbar/SimpleNavbar';
import Footer from '../Footer/Footer';

function Loading() {
  const {user} = useContext(UserContext)
  return (
   <>
   {
    user ? <Navbar/> : <SimpleNavbar/>
   }


   <div className='LoadingInFetchingBooksContainer'>
      <div className='LoadingInFetchingBooks'>
   <Icon icon="eos-icons:bubble-loading" width="1.2em" height="1.2em" />
      </div>
    </div>
    
   </>
  )
}

export default Loading;