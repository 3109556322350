import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import dummyProfile from './../../../Asserts/Images/DummyProfile.png'

function Comments({ comments, user, likeComment, unlikeComment, deleteComment, addComment }) {
    const [newComment, setNewComment] = useState('');
    const [likedComments, setLikedComments] = useState({});
    const [delPopup,setDelPopup] = useState(null)
    const [deletepopup,setDeletepopup] = useState(false)
    const [deleteReason,setDeleteReason] = useState('')

    function DelPopup (index) {
        if (delPopup === index){
            setDelPopup(null)
        }
        else{
        setDelPopup (index)
        }
    }
    function DelPopup2 (index) {
        
        setDeletepopup (index)
        
    }

    function byDelCmnt (cmntId) {
        deleteComment(cmntId);
        setDelPopup(false)
        setDeleteReason('')
        setDeletepopup(false)
    }

    useEffect(() => {
        const initialLikedStatus = {};
        comments.forEach((comment) => {
            if (comment.likes && Array.isArray(comment.likes)) {
                initialLikedStatus[comment._id] = comment.likes.includes(user._id);
            }
        });
        setLikedComments(initialLikedStatus);
    }, [comments]);

    const handleAddComment = () => {
        if (newComment.trim()) {
            addComment(newComment);
            setNewComment(''); // Clear the input field after submitting
        }
    };

    const handleLikeToggle = async (commentId) => {
        if(!user){
            alert("الرجاء تسجيل الدخول أولا")
            return
        }
        let isLiked;
        try {
            // Check the current like status
            isLiked = likedComments[commentId] || false;

            // Optimistically update the like status
            setLikedComments((prevLikedComments) => ({
                ...prevLikedComments,
                [commentId]: !isLiked,
            }));
            console.log(isLiked);
            // Call the appropriate API based on current status
            if (isLiked) {
                await unlikeComment(commentId);
            } else {
                await likeComment(commentId);
            }
        } catch (err) {
            console.error(err);
            // Rollback optimistic update on error
            setLikedComments((prevLikedComments) => ({
                ...prevLikedComments,
                [commentId]: isLiked,
            }));
        }
    };

    return (
        <div className="commentsmaindiv buyabookaddnewclass">
            <div className="commentcardcontainer">
            
                {comments.length>0?comments.map((comment,index) => (
                    <div className='Comment-container'>
                        {
                            deletepopup === index &&
                            <div className="deletemaindiv">
              <div className="deletecontainer">
                <div className="deletetitle">
                  <div>سبب الحذف</div>
                  <div
                    className="CloseHadafPopup"
                    onClick={() => setDeletepopup(false)}
                  >
                    x
                  </div>
                </div>
                <textarea
                  placeholder="مختلف عن موضوع القسم"
                  value={deleteReason}
                  onChange={(e) => setDeleteReason(e.target.value)}
                />
                <button onClick={()=>byDelCmnt(comment?._id)}>
                  <Icon
                    icon="ic:baseline-delete"
                    width="1.2em"
                    height="1.2em"
                  />{" "}
                  حذف
                </button>
              </div>
            </div>
                        }
                        <div style={{position:'relative'}}>
                        {user && user.role === 'admin' && (
                                    <div
                                        className="deleteCommentButton"
                                        onClick={() => DelPopup(index)}
                                        // deleteComment(comment?._id)
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <Icon icon="pepicons-pop:dots-y" />
                                    </div>
                                )}
                                {
                                    delPopup === index ? <div className='cmnt-del-popup'
                                    onClick={()=>DelPopup2(index)}
                                    >
                                    <div>
                                    حذف
                                    </div>
                                </div> : <></>
                                }
                                
                        </div>
                    <div className="commentdivmain" key={comment?._id}>
                        <div className="commentdivleft">
                            <div>{comment?.commentedBy?.first_name}</div>
                            <div>{comment?.comment}</div>
                            <div>
                                <div>{new Date(comment.commentedDate).toLocaleDateString()}</div>
                                <div
                                    className={likedComments[comment?._id] ? 'comliked red-heart' : 'hearticondis'}
                                    onClick={() => handleLikeToggle(comment?._id)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <Icon icon="ph:heart-fill" width="1.2em" height="1.2em" />
                                    {comment?.likes?.length > 0 ? comment?.likes?.length : ''}
                                </div>
                                
                            </div>
                        </div>
                        <div className="commentdivright">
                            <img src={comment?.commentedBy.photo ? comment.commentedBy.photo : dummyProfile } alt="user" />
                        </div>
                    </div>
                    </div>
                )):<><div className="nocommentsdivmain">
                    لا يوجد تعليقات
                    </div></>}
            </div>
{
    user ? <>
    <div className="bottommessagediv">
        {
            user.photo ? <div className='comment-profile-pic'>
            <img src={user.photo} alt=''/>
            </div> : 
            <Icon icon="teenyicons:user-circle-solid" width="1.2em" height="1.2em" />
        }
                
                
                
                
                <input
  type="text"
  placeholder="اضف تعليق"
  value={newComment}
  onChange={(e) => setNewComment(e.target.value)}
  onKeyDown={(e) => {
    if (e.key === "Enter") {
      handleAddComment();
    }
  }}
/>
                
                <Icon
                    icon="majesticons:send"
                    width="1.2em"
                    height="1.2em"
                    onClick={handleAddComment}
                    style={{ cursor: 'pointer' }}
                />
                
            </div>
    </>:<></>
}
            
        </div>
    );
}

export default Comments;
