import React, {useEffect, useState} from 'react';
import './Paymenthis.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../../context/UserContext';
import Paymentdetail from '../Paymentdetail/Paymentdetail';
import axios from 'axios';
import Loading from '../Loading/Loading';
import nopayment from '../../../Asserts/Images/nopayment.png'
import { useSessionErrorHandler } from '../Managesession';
import BranchLoading from '../branchLoading/BranchLoading';


function Paymenthis() {
  const handleSessionError = useSessionErrorHandler();
  const navigate = useNavigate();
const {user,loading,setLoading,token} = useContext(UserContext);
const [payments,setpayment] = useState([])

const [stts,setStts] = useState('Success')


const getuserPayements = async()=>{

  setLoading(true)
  axios.get(`${process.env.REACT_APP_SERVER_URL}/payments/user/${user._id}`,{
    headers:{
      authorization:`Bearer ${token}`
    }
  }).then((response)=>{
    console.log(response.data.payments)
    setpayment(response.data.payments)
    setLoading(false)

  }).catch((err)=>{
    handleSessionError(err)

    setLoading(false)
    console.log(err)
  })
}
const handleAllButtonClick = async(status)=>{
  setLoading(true)
  await axios.get(`${process.env.REACT_APP_SERVER_URL}/payments/all?status=${status}`,{
    headers:{
      authorization:`Bearer ${token}`
    }
  }).then((res)=>{
    console.log(res)
    setpayment(res.data.transcition)
    setLoading(false)
  }).catch((err)=>{
    handleSessionError(err)
    setLoading(false)
    console.log(err)
  })
}
const handleTodayButtonClick = async(status)=>{
  try{
    setLoading(true)
     await axios.get(`${process.env.REACT_APP_SERVER_URL}/payments/today/payments?status=${status}`,{
      headers:{
        authorization:`Bearer ${token}`
      }

     }).then((res)=>{
        console.log(res)
        setpayment(res.data.payments)
        setLoading(false)
      })

  }catch(err){
    handleSessionError(err)

    console.log(err)
    setLoading(false)
  }
}
const handleTranscitionData = async()=>{
  if(user?.role===("admin"||"super-admin")){
    return handleAllButtonClick('Success')
  }
  else if(user?.role === 'user'){
     getuserPayements()
  }
}
useEffect(()=>{
handleTranscitionData()

},[])
  const [activeButton, setActiveButton] = useState('all');
const [paymentdet ,setPaymentdet] = useState("payment");
const [paymentid ,setpaymentid] = useState("")
  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
  };

  function fetchByStatus (status) {
    setStts(status)
      handleAllButtonClick(status)
  }
function fetchBySubStts (subStts) {
  setActiveButton(subStts)
  if(subStts === 'today'){
    handleTodayButtonClick(stts)
    
  }
  else{
    handleAllButtonClick(stts)
  }
}


  const goback = (id) => {
    navigate(`/paymentdetail/${id}?not=${true}`)
    // setPaymentdet("paymentdetail");
  };

  return (

    <>

      <div className="paymenthismain">
        <div className="paymenthisnav">
          <Navbar />
        </div>

        {
          paymentdet === "payment" &&
           <>



        <div className="paymenthiscontainer">
          <div className="paymenthistitle">
            مدفوعاتي
          </div>

        {
          (user.role === "admin"||user.role === "super-admin") && <>
          <div className='head-btns-main'>
          <div className='upper-btn-container'>
          <div className={stts === 'Success' ? 'upper-btn-green-active' : 'upper-btn-green-inactive'}>
              <button onClick={()=>fetchByStatus('Success')}>
              تنجح
              </button>
            </div>
            <div className={stts === 'Pending' ? 'upper-btn-yellow-active' : 'upper-btn-yellow-inactive'}>
              <button onClick={()=>fetchByStatus('Pending')}>
              قيد الانتظار
              </button>
            </div>
            <div className={stts === 'Declined' ? 'upper-btn-red-active' : 'upper-btn-red-inactive'}>
              <button onClick={()=>fetchByStatus('Declined')}>
              انخفض
              </button>
            </div>
          </div>
            <div className="paymentnavigationmain">
        <div
          className={activeButton === 'all' ? 'payactive1' : 'payinactive1'}
          onClick={() => fetchBySubStts('all')}
        >
          الكل
        </div>
        <div
          className={activeButton === 'today' ? 'payactive' : 'payinactive'}
          onClick={()=> fetchBySubStts('today')}
        >
          اليوم
        </div>
      </div>
      </div>
          </>
        }

{

  loading ? <BranchLoading/> : <>{
    payments&& payments.length>0? payments.map((payment) => {
      // Convert the created_at string to a Date object


      return (
        <div
          key={payment.id}
          className="paymenttopmain"
          onClick={()=> goback(payment.transcition_id)}
          style={{ cursor: 'pointer' }}
        >
          <div className="paymenttopleft">
         {`ريال ${payment.transcition_amount}`}
          </div>
          <div className="payenttopright">
            <div>{payment.transcition_description}</div>
            <div>
              <span>{payment?.transcitionTime}</span> {payment?.transcitionDay} {payment?.transcitionDate}
            </div>
             <div><span>المستخدم: </span>{payment?.transcition_by?.first_name+" "+payment?.transcition_by?.last_name}</div>
            <div><span>المستلم: </span>{payment?.seller_information}</div>
            <div><span>الحالة: </span>{payment.transcition_status}</div>
          </div>
        </div>
      );
    }):
    <>
    <div className="nopaymentmain">
    <img src={nopayment} alt="" />
    لا يوجد أي مدفوعات
    </div>

    </>
  }</>

}
        </div>

        </>
        }

{
          paymentdet === "paymentdetail" &&
           <>
{/* <Paymentdetail  paymentid ={paymentid}/> */}
           </>
}
        <div className="paymenthisfooter">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Paymenthis;