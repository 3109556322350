import React, { useContext, useState } from 'react'
import { UserContext } from '../../../context/UserContext'
import { useSessionErrorHandler } from '../../User/Managesession'
import axios from 'axios'
import Navbar from '../../User/Navbar/Navbar'
import Footer from '../../User/Footer/Footer'
import './paymentstatus.css'
import Loading from '../../User/Loading/Loading'
export default function PaymentStatus() {
    const {loading,token, setLoading}= useContext(UserContext)
    const handleSessionError = useSessionErrorHandler()
    const [payment ,setStatus] = useState({})
    const [paymentId,setPaymentId] = useState('')
    const handleAllButtonClick = async()=>{
        setLoading(true)
        console.log(paymentId)
        setLoading(true)
        await axios.post(`${process.env.REACT_APP_SERVER_URL}/edfapay/orderstatus`,{
            orderid:paymentId
        },{
            headers:{
                authorization:`Bearer ${token}`
            }
        }).then((res)=>{
            console.log(res)

            setStatus(res.data.response)
            setLoading(false)
            }
        ).catch((err)=>{
            handleSessionError(err)
            setLoading(false)
            console.log(err)
        }

        )
        setLoading(false)
    }




  return (
    loading ? <Loading/> :
    <div>
        <div>
            <Navbar/>
        </div>
        <div className='invoice-data-input'>
            <div className='invoice-data-input-field'>
            <input type="text" placeholder='أدخل الرقم المرجعي للطلب' onChange={(e)=>setPaymentId(e.target.value)} value={paymentId}/>
            </div>
            <div className='invoice-data-input-btn'>
            <button onClick={handleAllButtonClick}>يبحث</button>
            </div>
        </div>
        
        
        {/* <div>{JSON.stringify(payment)}</div> */}
    {Object.keys(payment).length > 0 &&<>
    <div className='data-container-payment'>
     <h1>Payment Details</h1>
     <div className='payment-details-data-container'>
        <table>
        <tr>
                <td>Date</td>
                <td>{payment.date}</td>
            </tr>
            <tr>
                <td>Brand</td>
                <td>{payment.brand}</td>
            </tr>
            <tr>
                <td>Reference Number</td>
                <td>{payment?.order?.number}</td>
            </tr>
            <tr>
                <td>Amount</td>
                <td>{payment?.order?.amount}</td>
            </tr>
            <tr>
                <td>Currency</td>
                <td>{payment?.order?.currency}</td>
            </tr>
            <tr>
                <td>Order Description</td>
                <td>{payment?.order?.description}</td>
            </tr>
            <tr>
                <td>Payment Status</td>
                <td>{payment?.order?.status}</td>
            </tr>
        </table>
        </div>
        <h1>Customer Details</h1>
        <div className='payment-details-data-container'>
            <table>
                <tr>
                    <td>Customer Name</td>
                    <td>{payment?.customer?.name}</td>
                </tr>
                <tr>
                    <td>Customer Email</td>
                    <td>{payment?.customer?.email}</td>
                </tr>
            </table>
        </div>
        </div>
         </>}

<div>
    <Footer/>
</div>
    </div>
  )
}
