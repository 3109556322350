import React, { useContext, useState } from "react";
import "./interviewdetails.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { socket } from "../Bloodimageanalysis/globalsocket";
import { useSessionErrorHandler } from "../Managesession";
import { UserContext } from "../../../context/UserContext";
import { getCurrentDate, getCurrentTime, getCurrentTimeISO } from "../../../HelperFunctions";
function Messagemeetiglink({
  setMeetingData,
  setLoading,
  loading,
  handlemeeetingpop,
  gotosuccess,
  MeetingData,
}) {
  const [reply, setReply] = useState("");
  const { interviewID } = useParams();
  const { token ,user} = useContext(UserContext);
  const handleSessionError = useSessionErrorHandler();
  const updateMeetingStatus = async (status) => {
    try {
      setLoading(true);
      await axios
        .patch(
          `${process.env.REACT_APP_SERVER_URL}/meeting/updateMeeting/${interviewID}?meeting_status=${status}`,
          {},
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          const socketData = {
            Link: `/Interviewdetail/${response.data.data._id}`,
            Message: `اجتماعك ${status} دكتور مازن`,
            userID: response.data.data.Meeting_Req_User._id,
            Deliver_To: "user",
            time: getCurrentTime(),
            date: getCurrentDate(),
            created_at: getCurrentTimeISO(),
          };
          socket.emit("notification", socketData);
          console.log("updated Response", response);
          setMeetingData(response.data.data);

          setLoading(false);
        })
        .catch((err) => {
          handleSessionError(err);
          console.log(err);
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const updateReplayedMessage = async()=>{
    try{
      // setLoading(true);
      await axios.patch(`${process.env.REACT_APP_SERVER_URL}/meeting/updateReplayedMessages/${interviewID}`,{
        replayedMessages:reply
      },{
        headers:{
          authorization:`Bearer ${token}`
        }
      }).then((response)=>{
        console.log('updated Response',response);
        // setLoading(false);

        setMeetingData(response.data.response)


      }
      ).catch((err)=>{
        handleSessionError(err);
        console.log(err);
        // setLoading(false);
      })


    }catch(err){
      console.log(err);
    }
  }
  return (
    <>
      <div className="inputsdivmeetingmain">
        {/* <div className="interviewtexth2">
          <h2> تأكيد المعاد</h2>
        </div> */}

        {user?.role==='admin'&&<div className="testareadivflex">
          <label htmlFor="">رسالتك </label>
          <textarea
            name=""
            onChange={(e) => setReply(e.target.value)}
            id=""
          ></textarea>
        </div>}
       {reply.length>0? <div className="buttonsmaindiv2">
          <button onClick={updateReplayedMessage}>أرسل رسالة</button>
        </div>:""}
        {/* <div className="inputdivflexdiv">
          <label htmlFor="">رابط اللقاء </label>
          <input type="text" />
        </div> */}

        <div className="buttonsmaindiv2">
        {MeetingData?.Meeting_category!=="اللقاءات المحجوزة"&&MeetingData?.Meeting_category!=="اللقاءات المنتهية"&& <> <button onClick={() => updateMeetingStatus("اللقاءات المرفوضة")}>
            رفض اللقاء
          </button>
          <button onClick={() => updateMeetingStatus("لقاءات غير مدفوعة")}>
            تأكيد اللقاء
          </button> </>}
          {MeetingData?.Meeting_category==="اللقاءات المحجوزة"&&<button onClick={()=>updateMeetingStatus("اللقاءات المنتهية")} >نهاية الاجتماع
</button>}
        </div>
      </div>
    </>
  );
}

export default Messagemeetiglink;
