import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../../context/UserContext';

const RestrictionsRules = ({ children }) => {
  const { user,verification } = useContext(UserContext);

  const navigate = useNavigate()
  // Check if the user is logged in
  function gotodashboard () {
    navigate('/')
  }
  if (!user) {
    // If not logged in, redirect to the login page
    // alert('Please Login First')
    // return <Navigate to="/" />
    return(
      <div>
        <div className="deletemaindiv">
                <div className="deletecontainer">
                  <div className="deletetitle">
                    <div>الرجاء تسجيل الدخول أولا</div>
                    
                  </div>

                  
                  <button onClick={gotodashboard}>
                    
                  تمام
                  </button>
                </div>
              </div>
      </div>
    )
  }

  // Get the user's role
//   const userRole = user.role;

  // Check if the user's role is in the allowedRoles list
  return children
};

export default RestrictionsRules;
