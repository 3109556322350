import React from 'react'
import { Document, Font, Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer'
import urduFont from './../../../../Asserts/Fonts/static/Cairo-SemiBold.ttf'
import { Icon } from '@iconify/react/dist/iconify.js'
import success from './../../../../Asserts/Images/SuccessCheck.png'
Font.register({
  family: 'urdu',
  src : urduFont,
})

const styles = StyleSheet.create({
  page:{
    padding: 30,
    fontSize: 12,
    lineHeight: 1.5,
    flexDirection: 'column',
    fontFamily: 'urdu',
    width : 500,
    height: 200,
    // direction: 'rtl'
  },
  succes: {
    marginHorizontal: 'auto',

  },
  header: {
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 10,
    marginTop: 20,
    color: '#333',
    fontWeight: 'bold',
  },
  section: {
    marginBottom: 15,
    padding: 10,
    border: '1pt solid #e5e5e5',
    borderRadius: 5,
  },
  label: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
    color: '#333',
  },
  value: {
    fontSize: 12,
    marginBottom: 2,
    color: '#555',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  totalAmount: {
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 20,
    color: '#222',
  },
  textColor: {
    color: '#5D5D5DC7',
    textAlign: 'right',
    marginTop: 5
  },
  image: {
    width: 70, // Define width and height for the image
  },
  statusColor:{
    color:'#B571AC',
  }
  ,
  line:{
    width:'100%',
    borderBottomWidth: 1,
    borderBottomColor: 'gray',
    marginVertical: 5,
  }
  ,
  centerText: {
    textAlign: 'center',
    fontSize: 14,
    marginVertical: 10,
    fontWeight: 'bold',
    color: '#333',
    direction:'rtl'
  },
  textRight: {
    textAlign: 'right',
    color: '#333',
  },
  textCenter: {
    textAlign: 'center',
    color: '#48B865',
  }
})

function PaymentTemplate({paymentdata}) {
  return (
    <Document>
      <Page style={styles.page} size={{width: 300, height:400}}>


        <View style={styles.succes}>

          <Image
          style={styles.image}
          src={success}
          />

          </View>
        <Text style={styles.textCenter}>تم الدفع بنجاح</Text>

        <Text style={styles.header}>{paymentdata?.transcition_amount} SR</Text>
        <View style={styles.line}></View>

        <View style={styles.row}>
          <View>
            <Text style={styles.statusColor}>
            المشتري
            </Text>
          </View>
          <View>
            <Text>
              {paymentdata?.transcition_by?.first_name+" "+paymentdata?.transcition_by?.last_name}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <View>
            <Text style={styles.statusColor}>البائع</Text>
          </View>
          <View>
            <Text>
              {paymentdata?.seller_information}
            </Text>
          </View>
        </View>
        <View style={styles.line}></View>
        <View style={styles.row}>
          <View>
            <Text style={styles.statusColor}>المبلغ</Text>
          </View>
          <View>
            <Text>{paymentdata?.transcition_amount}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View>
            <Text style={styles.statusColor}>رسوم إضافية</Text>
          </View>
          <View>
            <Text>{0}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View>
            <Text style={styles.statusColor}>الرقم المرجعي</Text>
          </View>
          <View>
            <Text>{paymentdata?.transcition_id}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View>
            <Text style={styles.statusColor}>التاريخ</Text>
          </View>
          <View>
            <Text>{paymentdata?.transcitionDate}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View>
            <Text style={styles.statusColor}>الوقت</Text>
          </View>
          <View>
            <Text>{paymentdata?.transcitionTime}</Text>
          </View>
        </View>
        {/* <View style={styles.row}>
          <View>
            <Text style={styles.statusColor}>وسيلة الدفع</Text>
          </View>
          <View>
            <Text>{'....'}</Text>
          </View>
        </View> */}
      </Page>
    </Document>
  )
}

export default PaymentTemplate
