import React from 'react';
import { Navigate } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';

const ProtectedRoute = ({ allowedRoles, children }) => {

    const {user} = useContext(UserContext);

  const userRole = user.role; // Replace with actual user role retrieval

  return allowedRoles.includes(userRole) ? children : <Navigate to="/" />;
};

export default ProtectedRoute;
