// import React, { useEffect } from 'react'
// import Footer from '../Footer/Footer';
// import Navbar from '../Navbar/Navbar';
// import './Sucessmessage.css'
// import { Icon } from '@iconify/react/dist/iconify.js';
// import { useNavigate, useParams } from 'react-router-dom';
// import { useContext } from 'react';
// import { UserContext } from '../../../context/UserContext';
// function Sucessmessage() {
//   const {success ,successAction} = useContext(UserContext);
//   console.log(success);
//   const navigate = useNavigate()
//  useEffect(() => {
//   setTimeout(()=>{
//     navigate(`${successAction}`)
//   },1000)
//  }, [])

//   return (
//   <>
//   <div className="suceessmessmain">
// <div className="sucessnavbar">
// <Navbar/>
// </div>

// <div className="sucesscontainerfsdf">
//     <div className="circlegreentick">
//     <Icon icon="hugeicons:tick-02" width="1.2em" height="1.2em" />
//     </div>
//     <div className="suceesmessageis">
//     {/* تم ارسال الطلب بنجاح */}
//     {success}
//     </div>
// </div>

// {/* <div className="sucessmessagefooter">
//     <Footer/>
// </div> */}

//   </div>
//   </>
//   )
// }

// export default Sucessmessage;


import React, { useEffect, useContext } from 'react';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';
import './Sucessmessage.css';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import SimpleNavbar from '../SimpleNavbar/SimpleNavbar';

function Successmessage() {
  const { success, successAction,paymentSuccessAction } = useContext(UserContext);
  console.log(success);
  console.log(successAction)
  console.log(paymentSuccessAction)
  const navigate = useNavigate()
 useEffect(() => {


  setTimeout(()=>{
    navigate(successAction)
  },1000)
 }, [])



  return (
    <>
      <div className="suceessmessmain">
        <div className="sucessnavbar">
         <SimpleNavbar/>
        </div>
        <div className="sucesscontainerfsdf">
          <div className="circlegreentick">
            <Icon icon="hugeicons:tick-02" width="1.2em" height="1.2em" />
          </div>
          <div className="suceesmessageis">
            تم ارسال الطلب بنجاح
          </div>
        </div>
        {/* <div className="sucessmessagefooter">
          <Footer />
        </div> */}
      </div>
    </>
  );
}

export default Successmessage;


